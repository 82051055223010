/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons


import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import DashboardPage from "merchantViews/Dashboard/Dashboard.js";
import VirtualTerminalPage from "merchantViews/VirtualTerminal/VirtualTerminal.js"
import Settings from "merchantViews/Settings/Settings.js";
import TransactionList from "merchantViews/TransactionList/TransactionList.js";
import Batch from "merchantViews/BatchList/BatchList.js";
import Category from "merchantViews/Category/Category.js";
import ItemListing from "merchantViews/ItemListing/ItemListing.js";
import DeviceManager from "merchantViews/DeviceManager/DeviceManager.js";
import TaxList from "merchantViews/TaxList/TaxList.js";
import Discount from "merchantViews/Discount/Discount.js";
import ModifierList from "merchantViews/ModifierList/ModifierList.js";
import Employee from "merchantViews/Employee/Employees.js";
import Role from "merchantViews/Role/Role.js";
import Tip from "merchantViews/Tip/Tip.js";
import InActiveLocation from "merchantViews/InActiveLocation/InActiveLocation.js";
import StoreIcon from '@material-ui/icons/Store';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PartyModeSharpIcon from '@material-ui/icons/PartyModeSharp';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import GroupsIcon from '@material-ui/icons/Group';
import ExposureIcon from '@material-ui/icons/Exposure';
import AppSettingsAltIcon from '@material-ui/icons/SettingsCell';
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightness';

import SalesOverview from "merchantViews/ReportSalesOverview/SalesOverview";
import ReportEmployees from "merchantViews/ReportEmployees/ReportEmployee";
import ReportDiscounts from "merchantViews/ReportDiscount/ReportDiscount";
import ReportTaxes from "merchantViews/ReportTaxes/ReportTaxes";
import ReportItemSales from "merchantViews/ReportItemSales/ReportItemSales";
import ReportCardTypes from "merchantViews/ReportCardTypes/ReportCardTypes";
import ReportSalesReport from "merchantViews/ReportSalesReport/ReportSalesReport";
import AssessmentIcon from '@material-ui/icons/Assessment';
import TableChart from '@material-ui/icons/TableChart';
import ListAlt from '@material-ui/icons/ListAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import Logout from "merchantViews/Logout/Logout.js";
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import ComputerIcon from '@mui/icons-material/Computer';
import DvrIcon from '@mui/icons-material/Dvr';

import VirtualTerminalOrders from "merchantViews/VirtualTerminalOrder/VirtualTerminalOrder";
import TransactionDetail from "merchantViews/TransactionDetail/TransactionDetail.js";
import CardHistory from "merchantViews/CardHistory/CardHistory.js";
import SettingsIcon from '@mui/icons-material/Settings';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CategoryIcon from '@mui/icons-material/Category';
import TranslateIcon from '@mui/icons-material/Translate';
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: " ",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/user",
  },
  
  {
    path: "/virtualterminalll",
    name: "Virtual Terminal",
    rtlName: "  ",
    icon: ComputerIcon,
    component: VirtualTerminalPage,
    layout: "/user",
    subMenuFlag:"1",
    subMenu: [
       {
        path: "/pay_order",
        name: "Charge",
        rtlName: " ",
        icon: PaymentsIcon,
        component: VirtualTerminalPage,
        layout: "/user",
        subKeyVal:14
        
      },
      {
        path: "/orders",
        name: "Orders",
        rtlName: " ",
        icon: DvrIcon,
        component: VirtualTerminalOrders,
        layout: "/user",
        subKeyVal:15
        
      },
    ]
  },

  {
    path: "/settingsss",
    name: "Settings",
    rtlName: "  ",
    icon: SettingsIcon,
    component: Settings,
    layout: "/user",
    subMenuFlag:"1",
    subMenu: [
       {
        path: "/settings",
        name: "Settings",
        rtlName: " ",
        icon: SettingsBrightnessIcon,
        component: Settings,
        layout: "/user",
        subKeyVal:12
        
      },
      {
        path: "/device_manager",
        name: "Device Manager",
        rtlName: " ",
        icon: AppSettingsAltIcon,
        component: DeviceManager,
        layout: "/user",
        subKeyVal:13
        
      },
    ]
  },
  {
    path: "/transactions",
    name: "Transactions",
    rtlName: " ",
    icon: ContentPasteIcon,
    component: TransactionList,
    layout: "/user",
  },
  {
    path: "/batch",
    name: "Batch Report",
    rtlName: " ",
    icon: ContentPasteIcon,
    component: Batch,
    layout: "/user",
  },
  {
    path: "/modifierrr",
    name: "Item Catalog",
    rtlName: " ",
    icon: StoreIcon,
    component: ItemListing,
    layout: "/user",
    subMenuFlag:"1",
    subMenu: [
      {
        path: "/category",
        name: "Categories",
        rtlName: " ",
        icon: CategoryIcon,
        component: Category,
        layout: "/user",
        subKeyVal:9
      },
      {
        path: "/modifier",
        name: "Modifier Groups",
        rtlName: " ",
        icon: PartyModeSharpIcon,
        component: ModifierList,
        layout: "/user",subKeyVal:10
      },
      {
        path: "/item",
        name: "Items",
        rtlName: " ",
        icon: StoreIcon,
        component: ItemListing,
        layout: "/user",
        subKeyVal:11
        
      },
      
    ]
  },
 
  {
    path: "/in_active",
    name: "In Active",
    rtlName: " ",
    icon: AccountBalanceWalletIcon,
    component: InActiveLocation,
    layout: "/user"
  },
  {
    path: "/tax",
    name: "Taxes",
    rtlName: " ",
    icon: AccountBalanceWalletIcon,
    component: TaxList,
    layout: "/user"
  },
  {
    path: "/discount",
    name: "Discounts",
    rtlName: " ",
    icon: ExposureIcon,
    component: Discount,
    layout: "/user"
  },
  {
    path: "/Employee",
    name: "Employees",
    rtlName: " ",
    icon: GroupsIcon,
    component: Employee,
    layout: "/user",
  }, 
  {
    path: "/Role",
    name: "Roles",
    rtlName: " ",
    icon: AccountCircleIcon,
    component: Role,
    layout: "/user",
  } ,
  {
    path: "/Tip",
    name: "Tips",
    rtlName: " ",
    icon: AddBoxIcon,
    component: Tip,
    layout: "/user",
    submenu:[{title:"aaaa"}
  ]
  },   
  {
    path: "/reportsss",
    name: "Reports",
    rtlName: "  ",
    icon: TableChart,
    component: SalesOverview,
    layout: "/user",
    subMenuFlag:"1",
    subMenu: [
      
      // {//
      //   path: "/SalesOverview",
      //   name: "Sales Overview",
      //   rtlName: " ",
      //   icon: ListAlt,
      //   component: SalesOverview,
      //   layout: "/user",
      //   subKeyVal:14
        
      // },
      {
        path: "/SalesReport",
        name: "Sales Report",
        rtlName: " ",
        icon: AssessmentIcon,
        component: ReportSalesReport,
        layout: "/user",
        subKeyVal:15
        
      },
     
      {
        path: "/ReportTenderAndCardTypes",
        name: "Tender & Card Types",
        rtlName: " ",
        icon: CreditCardIcon,
        component: ReportCardTypes,
        layout: "/user",
        subKeyVal:16
        
      },
      //  {
      //   path: "/ReportItemSales",
      //   name: "Item Sales",
      //   rtlName: " ",
      //   icon: "report",
      //   component: ReportItemSales,
      //   layout: "/user",
      //   subKeyVal:17
        
      // },
      {
        path: "/ReportTaxes",
        name: "Report Taxes",
        rtlName: " ",
        icon: PaymentsIcon,
        component: ReportTaxes,
        layout: "/user",
        subKeyVal:18
        
      },
      // {//
      //   path: "/ReportDiscount",
      //   name: "Discounts",
      //   rtlName: " ",
      //   icon: "report",
      //   component: ReportDiscounts,
      //   layout: "/user",
      //   subKeyVal:19
        
      // },
      {
        path: "/ReportEmployees",
        name: "Employee Sales",
        rtlName: " ",
        icon: AssessmentIcon,
        component: ReportEmployees,
        layout: "/user",
        subKeyVal:20
        
      },
     
    ]
  },
  {
    path: "/transaction_detail",
    name: "TransactionDetail",
    rtlName: " ",
    icon: LogoutIcon,
    component: TransactionDetail,
    layout: "/user",
  },
  {
    path: "/card_history",
    name: "card history",
    rtlName: " ",
    icon: LogoutIcon,
    component: CardHistory,
    layout: "/user",
  },

  {
    path: "/logout",
    name: "Logout",
    rtlName: " ",
    icon: LogoutIcon,
    component: Logout,
    layout: "/user",
  },
  {
    path: "/changeLanguage",
    name: "English | Español",
    rtlName: " ",
    icon: TranslateIcon,
    component: "",
    layout: "/admin",
  },
  
];

export default dashboardRoutes;
