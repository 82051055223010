/*
  @page Device manager
*/

//Import require libraries
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import RemoveRedEyeRoundedIcon from "@material-ui/icons/RemoveRedEyeRounded";
import Helper from "./../../util/Helper";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CancelIcon from "@material-ui/icons/Cancel";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import { CSVLink, CSVDownload } from "react-csv";
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import TransactionDetail from "merchantViews/TransactionDetail/TransactionDetail.js";

import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";

import InputAdornment from "@mui/material/InputAdornment";
import CodeIcon from "@mui/icons-material/Code";


import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/*
  @const Page styles
*/
const styles = {
  grayBg: {
    background: "#8F95A3 !important",
  },
  root: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "#ffa726",
    },
  },
  rowPerPage: {
    minHeight: "40px",
    height: "40px",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#ffa726",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox: {
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    color: "#495057",
    width: "93%",
    height: "45px",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  datepicker: {
    width: "100%",
  },
  partialrefundamnt: {
    marginTop: "0px !important",
  },
  partialrefundpopupbuttonsection: {
    padding: "22px !important",
    paddingBottom: "30px !important",
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);

/*
  @function Transaction list page logic
*/
export default function TableList() {
  const {t} = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [openSendReceipt, setOpenSendReceipt] = useState(false);
  const [openSMSReceipt, setOpenSMSReceipt] = useState(false);
  const [sendNumber, setSendNumber] = useState("");
  const [sendEmail, setSendEmail] = useState("");
  const [ecrRefNo, setEcrRefNo] = useState(0);
  const [transactionId, setTransactionId] = useState(0);
  const [locationID, setLocationID] = useState(0);
  const [parentID, setParentID] = useState(0);
  const [openVoidPopup, setOpenVoidPopup] = useState(false);
  const [openReturnPopup, setOpenReturnPopup] = useState(false);
  const [partialRefundAmount, setPartialRefundAmount] = useState("");
  const [availableRefund, setAvailableRefund] = useState(0);
  const [openPartialReturnPopup, setOpenPartialReturnPopup] = useState(false);
  const [openRequestResponsePopup, setOpenRequestResponsePopup] =
    useState(false);
  const [openReturnTypePopup, setOpenReturnTypePopup] = useState(false);
  const [openItemRefundPopup, setOpenItemRefundPopup] = useState(false);
  const [voidReturnPopup, setVoidReturnPopup] = useState(false);
  const [amt, setAmt] = useState(0);
  const [tip, setTip] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [filterDailogOpen, setFilterDailogOpen] = useState(false);
  const [filterAmtFrom, setFilterAmtFrom] = useState("");
  const [filterAmtTo, setFilterAmtTo] = useState("");
  const [lastFour, setLastFour] = useState("");
  const [filterTenderType, setFilterTenderType] = useState([]);
  const [filtercardType, setFilterCardType] = useState([]);
  const [filterLocation, setFilterLocation] = useState([]);
  const [filterTransactionType, setFilterTransactionType] = useState([]);
  const [filterPaymentRes, setFilterPaymentRes] = useState([]);
  const [empDropDownVal, setEmpDropDownVal] = useState([]);
  const [locationDropDownVal, setLocationDropDownVal] = useState([]);
  const [devicesDropDownVal, setDevicesDropDownVal] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(10);
  const [currentPages, setCurrentPages] = useState(1);
  const [csvData, setCsvData] = useState([]);
  const [exportButtonLabel, setExportButtonLabel] = useState(t('lbl_export'));
  const [settingData, setSettingData] = useState("");
  const [locationIdMap, setLocationIdMap] = useState({});
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [selectedLocationName, setSelectedLocationName] = useState("");
  const [accessPage, setAccessPage] = useState(1);
  const [id, setId] = useState(0);
  const [serial_number, setSerialNumber] = useState("");
  const [orderItem, setOrderItem] = useState([]);
  const [selectRefundAmt, setSelectRefundAmt] = useState(0.0);
  const [retrunItemId, setReturnItemId] = useState([]);
  const [returnType, setReturnType] = useState("");
  const [devicePlatform, setDevicePlatform] = useState("");
  const [voidReturnTransId, setVoidReturnTransId] = useState("");
  const [transRequest, setTransRequest] = useState("{}");
  const [transResponse, setTransResponse] = useState("{}");
  const [permissionData, setPermissionData] = useState({});
  const [isJsonObjRes, setIsJsonObjRes] = useState(true);
  const [isJsonObjReq, setIsJsonObjReq] = useState(true);
  const [manualRefundRequestPopup, setManualRefundRequestPopup] =
    useState(false);
  const [refundRequestSendPopup, setRefundRequestSendPopup] = useState(false);
  const [kushkiManualRefundAmount, setKushkiManualRefundAmount] = useState("");

  const [manualRefundPrimaryId, setManualRefundPrimaryId] = useState(false);
  const [availableAmountForManualRefund, setAvailableAmountForManualRefund] = useState(false);
  const [manualRefundDataAskPopup, setManualRefundDataAskPopup] = useState(false);
  const [manualRefundTransactionId, setManualRefundTransactionId] = useState("");
  const [manualRefundAuthCode, setManualRefundAuthCode] = useState("");
  const [manualRefundAmount, setManualRefundAmount] = useState("");
  const [manualRefundNotes, setManualRefundNotes] = useState("");

  const addclass = () => {
    document.querySelector(".ps--active-y").classList.remove("newTop");
    document.querySelector(".ps--active-y").classList.add("newTop");
    setTimeout(function () {
      document.querySelector(".ps--active-y").classList.remove("newTop");
    }, 2000);
  };

  useEffect(() => {
    var is_location_active = localStorage.getItem("is_active");
    if (is_location_active === "0") {
      window.location.href = "/in_active";
      return;
    }
    const permissonData = JSON.parse(localStorage.getItem("permissonData"));

    if (permissonData) {
      if (
        permissonData.access_transaction_history === 0 ||
        permissonData.access_to_online_dashboard === 0
      ) {
        setAccessPage(0);
        return;
      }
      var permission = JSON.parse(localStorage.getItem("admin_permission"));
      setPermissionData(permission);
    }

    const data = { current_page: currentPages, rowPerPage: rowPerPage };
    getTransList(data);
    getReportFilterData();
    
  }, []);

  const validatePartialRefundValue = (e) => {
    var val = e.target.value;
    // if (val.length < 6) {
    var per = val.replace(".", "");
    per = per / 100;
    if (!isNaN(per)) {
      setPartialRefundAmount(parseFloat(per).toFixed(2));
    }
    // }
  };

  const validateKushkirefundAmnt = (e) => {
    var val = e.target.value;
    // if (val.length < 6) {
    var per = val.replace(".", "");
    per = per / 100;
    if (!isNaN(per)) {
      setKushkiManualRefundAmount(parseFloat(per).toFixed(2));
    }
    // }
  };

  const validateManualRefundAmount = (e) => {
    var val = e.target.value;
    // if (val.length < 6) {
    var per = val.replace(".", "");
    per = per / 100;
    if (!isNaN(per)) {
      setManualRefundAmount(parseFloat(per).toFixed(2));
    }
    // }
  };


  /*
    @function get filtered data using employee name and device
  */
  const getReportFilterData = () => {
    const data = {};
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    axios
      .post(window.apiUrl + "get_incorrect_transaction_filter_data", data, {
        headers: headers,
      })
      .then((response) => {
        // setEmpDropDownVal(response.data.locationData);
        setLocationDropDownVal(response.data.locationData);
        // var deviceArr = response.data.devices;        
        // setDevicesDropDownVal(deviceArr);
      })
      .catch((error) => {
        return error;
      });
  };

  function ParseFloat(str, val) {
    str = str.toString();
    str = str.slice(0, str.indexOf(".") + val + 1);
    return Number(str);
  }

  /*
    @function get Item listing for selection of refund
  */
  const getTransactionItemList = () => {
    var loadingHtml = [];
    loadingHtml.push(
      <div style={{ textAlign: "center", width: "100%" }}>
        <CircularProgress id="loginProgress"></CircularProgress>
      </div>
    );
    setOrderItem(loadingHtml);

    const data = {};
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.ecrref_num = ecrRefNo;
    axios
      .post(window.apiUrl + "item_wise_refund_with_calculated_price", data, {
        headers: headers,
      })
      .then((response) => {
        const itemRefundList = [];
        var itemtotal = 0;
        var mainOrderTotal = 0;
        var orderDiscount = 0;
        var orderDiscountType = "";
        var orderTipAmnt = 0;
        var orderNonCashAdj = 0;
        const priceArr = [];
        var arrItemAndPrice = [];

        var itemHtml = [];
        var totalItem = 0.0;
        var itemIds = [];
        response.data.orderItems.forEach((eachItems) => {
          console.log("&&&&&&&&&");
          console.log(eachItems.id);

          itemHtml.push(
            <div style={{ width: "100%", display: "flex" }} key={eachItems.id}>
              <div style={{ flexGrow: "1" }}>
                <FormControlLabel
                  id="refundItemCheckBox"
                  control={
                    <Checkbox
                      onChange={(e) => {
                        itemIds.push(eachItems.id);
                        setReturnItemId(itemIds);
                        totalItem += parseFloat(e.target.value);
                        setSelectRefundAmt(totalItem);
                      }}
                    />
                  }
                  value={eachItems.price}
                  label={eachItems.name}
                />
              </div>
              <div style={{ paddingTop: "8px" }}>
                <span style={{ fontWeight: "400" }}>${eachItems.price}</span>
              </div>
            </div>
          );
        });
        setOrderItem(itemHtml);
      })
      .catch((error) => {
        return error;
      });
  };

  const showRequestData = (ecr) => {
    setOpenRequestResponsePopup(true);
    const data = {
      ecr_ref_no: ecr,
    };

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "get_transaction_log", data, {
        headers: headers,
      })
      .then((response) => {
        // console.log(response.data[0].request_body);
        // console.log(response.data[0].respones);

        try {
          if (response.data[0].transit_api_request == "") {
            setTransRequest("Not Available");
          } else {
            var arr = [];
            for (var k in response.data) {
              arr.push(
                "-----------------------------" +
                response.data[k].respnse_type +
                " Request----------------------------"
              );
              arr.push(JSON.parse(response.data[k].transit_api_request));
            }
            setTransRequest(arr);
          }
        } catch (error) {
          setIsJsonObjReq(false);
          var arr = null;
          for (var k in response.data) {
            if (arr == null) {
              arr = response.data[k].transit_api_request;
            } else {
              arr += response.data[k].transit_api_request;
            }
          }
          setTransRequest(arr);
        }
        try {
          if (response.data[0].respones == "") {
            setTransResponse("{'':''}");
          } else {
            var arr = [];
            for (var k in response.data) {
              arr.push(
                "-----------------------------" +
                response.data[k].respnse_type +
                " Response----------------------------"
              );
              arr.push(JSON.parse(response.data[k].respones));
            }
            setTransResponse(arr);
          }
        } catch (error) {
          setIsJsonObjRes(false);
          var arr = null;
          for (var k in response.data) {
            if (arr == null) {
              arr = response.data[k].respones;
            } else {
              arr += response.data[k].respones;
            }
          }
          setTransResponse(arr);
          //   setError(true);
          
          // setOpenRequestResponsePopup(false);
        }
      })
      .catch((error) => {
        setError(true);
        setErrorMsg(t('msg_some_error_occur'));
        setOpenRequestResponsePopup(false);
        return error;
      });
  };
  /*
    @function get transaction list
  */
  const getTransList = (body) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    /* const dataSetting = {};    
    var settingData = {};
    dataSetting.parent_id = localStorage.getItem("parent_id");
    dataSetting.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "get_setting", dataSetting, {
        headers: headers,
      })
      .then((response) => {
        settingData = response.data;
      });*/
    const data = body;
    console.log("datadatadatadata", JSON.stringify(data));

    data.startDate = startDate;
    data.endDate = endDate;

    axios
      .post(window.apiUrl + "get_incorrect_transaction", data, {
        headers: headers,
      })
      .then((response) => {
        const userArr = [];
        const exportArr = [];
        console.log("******");
        console.log(response);
        setTotalPages(response.data.totalPage);
        setCurrentPages(response.data.current_page);

        var devicePlatForm = response.data.loc_data;
        setDevicePlatform(devicePlatForm);
        var devicePlatFormArr = [];

        console.log("******************************");
        console.log(devicePlatFormArr);
        response.data.transData.forEach((eachTransaction) => {
          var eachAmt = "";
          eachAmt = Helper.viewCurrencyFormat(
            parseFloat(eachTransaction.amount)
          );
          var t_date = new Date(eachTransaction.device_created_at);
          console.log(eachTransaction);

          const recLink =
            window.apiUrl.replace("/api/", "/receipt/") +
            eachTransaction.ecrref_num;
          if (eachTransaction.transaction_status == "1") {
            var transStatusIcon = (
              <span id="approvedTransIndentify">{t('lbl_approved')}</span>
            );
          } else if (
            eachTransaction.transaction_status == "2" ||
            eachTransaction.transaction_status == "3"
          ) {
            var transStatusIcon = (
              <span id="refundedTransIndentify">{t('lbl_approved')}</span>
            );
          } else if (eachTransaction.transaction_status == "4") {
            //Do not show void return button
            var transStatusIcon = (
              <span id="declinedTransIndentify">{t('lbl_failed')}</span>
            );
          } else if (eachTransaction.transaction_status == "5") {
            var transStatusIcon = (
              <span id="cancelledTransIndentify">{t('lbl_cancelled')}</span>
            );
          } else if (eachTransaction.transaction_status == "6") {
            var transStatusIcon = (
              <span id="cancelledTransIndentify">{t('lbl_cancelled')}</span>
            );
          } else if (
            eachTransaction.transaction_status == "7" ||
            eachTransaction.transaction_status == "8" ||
            eachTransaction.transaction_status == "9"
          ) {
            transStatusIcon = <span id="declinedTransIndentify">{t('lbl_failed')}</span>;
          } else if (eachTransaction.transaction_status == "10") {
            transStatusIcon = (
              <span id="manualPendingRefund">{t('lbl_pending_refund')}</span>
            );
          } else {
            transStatusIcon = <span id="unknownTransIndentify">{t('lbl_unknown')}</span>;
          }

          var voidBtnHtml = "";
          var manualRefundBtnHtml = "";
          var returnBtnHtml = "";

          console.log(
            "****" +
            eachTransaction.serial_number +
            " ### " +
            devicePlatFormArr[eachTransaction.serial_number]
          );
          console.log(devicePlatFormArr);
          if (
            eachTransaction.refund_amt >= 0.0 &&
            eachTransaction.is_void === "0" &&
            eachTransaction.transaction_type === "SALE" &&
            eachTransaction.response_code === "000000" &&
            (eachTransaction.payment_method === "CREDIT" ||
              eachTransaction.payment_method === "DEBIT") &&
            (devicePlatForm === "TSYS TransIT" ||
              devicePlatForm === "Billpocket")
          ) {
            var btnTxt = "";
            var lastRefundType = "";

            console.log(eachTransaction.refund_amt);
            if (
              eachTransaction.items === "Full Refund" ||
              eachTransaction.refund_amt === "0.00"
            ) {
              btnTxt = "full_refund";
              returnBtnHtml = (
                <Button
                  id="grayBgBtn"
                  variant="contained"
                  style={{
                    cursor: "not-allowed",
                    background: "#8F95A3 !important",
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                  color="primary"
                  className={classes.grayBg}
                >
                  {t('lbl_refunded')}
                </Button>
              );
            } else if (eachTransaction.items === "Partial Refund") {
              lastRefundType = "partial";
              btnTxt = t('lbl_partially_refunded');
            } else if (eachTransaction.items === "refund_items") {
              lastRefundType = "item";
              btnTxt = t('lbl_item_refunded');
            } else {
              btnTxt = t('lbl_refund_btn');
              lastRefundType = "not_refund";
              if (eachTransaction.batch_id === 0) {
                voidBtnHtml = (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#ffa726",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      setTransactionId(eachTransaction.transaction_id);
                      setLocationID(eachTransaction.location_id);
                      setParentID(eachTransaction.user_id);
                      setEcrRefNo(eachTransaction.ecrref_num);
                      setOpenVoidPopup(true);
                    }}
                  >
                    {t('lbl_void')}
                  </Button>
                );
              }
            }

            if (btnTxt !== "full_refund") {
              returnBtnHtml = (
                <Button
                  variant="contained"
                  style={{
                    whiteSpace:"nowrap",
                    backgroundColor: "#ffa726",
                    color: "#FFFFFF",
                    textTransform: "none",
                  }}
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    setReturnType(lastRefundType);
                    setOpenReturnTypePopup(true);
                    setTransactionId(eachTransaction.transaction_id);
                    setLocationID(eachTransaction.location_id);
                    setParentID(eachTransaction.user_id);
                    setId(eachTransaction.id);
                    setSerialNumber(eachTransaction.serial_number);
                    setEcrRefNo(eachTransaction.ecrref_num);
                    setPartialRefundAmount(eachTransaction.refund_amt);
                    setAvailableRefund(eachTransaction.refund_amt);
                    setAmt(eachTransaction.amount);
                    setTip(eachTransaction.tip_amt.replace(".", ""));
                  }}
                >
                  {btnTxt}
                </Button>
              );
            }

            // var btnTxt = "Refund";
            // if (eachTransaction.refund_amt === eachTransaction.amount ) {
            //   if(eachTransaction.batch_id === 0)
            //   {
            //     voidBtnHtml = <Button variant="contained" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', textTransform: 'none' }} color="primary" className={classes.button} onClick={() => { setTransactionId(eachTransaction.transaction_id); setEcrRefNo(eachTransaction.ecrref_num); setOpenVoidPopup(true) }} >Void</Button>
            //   }

            // } else if (eachTransaction.refund_amt === "0.00") {
            //   btnTxt = "Refunded"
            // } else {
            //   btnTxt = "Partially Refunded"
            // }
          } else {
            // voidBtnHtml = <Button variant="contained" style={{ backgroundColor: '#ffa726 !important', color: '#FFFFFF', textTransform: 'none' }} color="secondary"  disabled> Void </Button>
          }
          var voidReturnHtml = "";
          if (
            eachTransaction.batch_id == "0" &&
            eachTransaction.transaction_status == "2"
          ) {
            voidReturnHtml = (
              <Button
                variant="contained"
                style={{
                  whiteSpace:"nowrap",
                  backgroundColor: "#ffa726",
                  color: "#FFFFFF",
                  textTransform: "none",
                }}
                color="primary"
                className={classes.button}
                onClick={() => {
                  setVoidReturnTransId(eachTransaction.transaction_id);
                  setVoidReturnPopup(true);
                }}
              >
                {t('lbl_void_return')}
              </Button>
            );
          }
          //<CodeIcon onClick={showRequestData}></CodeIcon>
          //
          //if (eachTransaction.items === "Manual Refund") {
          if (eachTransaction.transaction_status == "10") {            
             if(localStorage.getItem("role") === "admin" && (permissionData.manual_refund == "1" || localStorage.getItem("admin_email") == "admin@magicpay.net") ) 
              {              
              manualRefundBtnHtml = (

                <Button
                  id = "manualRefundButton"
                  variant="contained"
                  color="primary"
                  style={{whiteSpace:"nowrap"}}
                  className={classes.button}
                  onClick={() => {
                    // setTransactionId(eachTransaction.transaction_id);
                    // setEcrRefNo(eachTransaction.ecrref_num);
                    setManualRefundDataAskPopup(true);
                    setManualRefundPrimaryId(eachTransaction.id);
                    setAvailableAmountForManualRefund(eachTransaction.amount);
                  }}
                >
                  {t('lbl_manual_refund')}
                </Button>

              );

            }
          }

          if (           
            devicePlatForm === "Billpocket"
          ) {
            var cardTypeLogo = "payment_card_icon/other.jpg";
            if (eachTransaction.payment_method == "CASH") {
              cardTypeLogo = "/payment_card_icon/cashIcon.png";
            } else if (eachTransaction.card_type == "VISA") {
              cardTypeLogo = "/payment_card_icon/newVisaLogo.png";
            } else if (eachTransaction.card_type == "MASTERCARD") {
              cardTypeLogo = "/payment_card_icon/master_card.png";
            } else if (eachTransaction.card_type == "DISCOVER") {
              cardTypeLogo = "/payment_card_icon/discover.png";
            } else if (
              eachTransaction.card_type == "CHINA UNIONPAY" ||
              eachTransaction.card_type == "CHINAUNIONPAY"
            ) {
              cardTypeLogo = "/payment_card_icon/unionPay.png";
            } else if (eachTransaction.card_type == "DINERS") {
              cardTypeLogo = "/payment_card_icon/master_card.png";
            } else if (
              eachTransaction.card_type == "AMERICAN EXPRESS" ||
              eachTransaction.card_type == "AMEX"
            ) {
              cardTypeLogo = "/payment_card_icon/american_express.png";
            } else if (eachTransaction.card_type == "ATH") {
              cardTypeLogo = "/payment_card_icon/ath.png";
            } else if (
              eachTransaction.card_type == "ATH MOBIL" ||
              eachTransaction.card_type == "ATH MOVIL"
            ) {
              cardTypeLogo = "/payment_card_icon/ath_movil.png";
            } else if (eachTransaction.card_type == "EBT") {
              cardTypeLogo = "/payment_card_icon/ebt.png";
            } else if (eachTransaction.card_type == "JCB") {
              cardTypeLogo = "/payment_card_icon/jcb.png";
            }
            userArr.push([
              eachTransaction.business_name,
              eachTransaction.merchant_id,
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleRedirectDetailPage(
                    eachTransaction.id,
                    eachTransaction.card_bin,
                    eachTransaction,
                    devicePlatForm
                  );
                }}
              >
                {eachTransaction.id}
              </span>,
              ,
              transStatusIcon,
              eachTransaction.payment_method,
              <span style={{color:(parseFloat(eachTransaction.amount_due) > 0.00)?"red":"",fontWeight:(parseFloat(eachTransaction.amount_due) > 0.00)?"bold":""}}>{eachAmt}</span>,
              eachTransaction.transaction_type == "SALE" &&
                eachTransaction.is_void != "0"
                ? "VOID"
                : eachTransaction.transaction_type,
              eachTransaction.card_number,
              <img src={cardTypeLogo} style={{ width: "50px" }}></img>,
              (eachTransaction.transaction_status != "10")?
              <a target="_blank" href={recLink}>
                <RemoveRedEyeRoundedIcon
                  style={{ color: "#ffa726" }}
                ></RemoveRedEyeRoundedIcon>
              </a>:<RemoveRedEyeRoundedIcon
                  style={{ color: "gray",cursor:"not-allowed" }}
                ></RemoveRedEyeRoundedIcon>,
              <div style={{ display: "flex" }}>
                {" "}
                <Button
                  variant="contained"   
                  style={{whiteSpace:"nowrap"}}               
                  id={(eachTransaction.transaction_status == "10")?"btnDisableGrayAdd":"transListEmailBtn"}
                  className={classes.button}
                  onClick={() => {
                    (eachTransaction.transaction_status != "10")?(setEcrRefNo(eachTransaction.ecrref_num),setAmt(eachTransaction.amount),setOpenSendReceipt(true)):""
                  }}
                >
                  {t('lbl_email')}
                </Button>
                <Button
                  variant="contained"
                  id={(eachTransaction.transaction_status == "10")?"btnDisableGrayAdd":"transListSmsBtn"}
                  style={{
                    marginLeft: "5px",                  
                    textTransform: "none",
                  }}
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    (eachTransaction.transaction_status != "10")?(setEcrRefNo(eachTransaction.ecrref_num),setOpenSMSReceipt(true)):""                    
                  }}
                >
                  {t('lbl_sms')}
                </Button>
                <Button
                id={(eachTransaction.transaction_status == "10")?"btnDisableGrayAdd":"transListPdfBtn"}
                  style={{
                    marginLeft: "5px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    (eachTransaction.transaction_status != "10")?downloadPdf(eachTransaction.ecrref_num):""
                  }}
                >
                  <PictureAsPdfIcon />
                </Button>{" "}
              </div>,
              <div style={{display:"flex",gap:"5px"}}>{devicePlatForm === "Billpocket" ? manualRefundBtnHtml : ""}
                {devicePlatForm === "Billpocket"
                  ? (returnBtnHtml)
                  : (voidBtnHtml, returnBtnHtml, voidReturnHtml)}
              </div>,
              localStorage.getItem("role") === "admin" &&
                (permissionData.access_log == "1" ||
                  localStorage.getItem("admin_email") == "admin@magicpay.net") ? (
                <CodeIcon
                  onClick={(e) => {
                    showRequestData(eachTransaction.ecrref_num);
                  }}
                ></CodeIcon>
              ) : (
                ""
              ),
              parseInt(t_date.getMonth() + 1) +
              "/" +
              t_date.getDate() +
              "/" +
              t_date.getFullYear(),
              t_date.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }),
            ]);
          } 
        });
        setTableData(userArr); // Set data inside table
      })
      .catch((error) => {
        return error;
      });
  };
  const history = useHistory();
  const handleRedirectDetailPage = (
    transDetailId,
    ccBin,
    transactionFullDatas,
    devicePlatForm
  ) => {
    history.push("/admin/transaction_detail", { transactionDetailId: transDetailId });
  };

  /*
    @function Sent receipt in email
  */
  const sendEmailReceipt = () => {
    const data = {
      receipt_email_address: sendEmail,
      ecr_ref_no: ecrRefNo,
      total_amount: amt,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "send_receipt_email", data, {
        headers: headers,
      })
      .then((response) => {
        setOpenSendReceipt(false);
        setSendEmail("");
        setEcrRefNo(0);
        setAmt(0);
        if (response.data.msg === "success") {
          //Check receipt was sent in email successfully
          setSuccess(true);
          setSuccessMsg(t('msg_receipt_sent_successfully'));
        } else {
          setError(true);
          setErrorMsg(t('msg_Some_Error_Occur_Receipt_Not_Sent'));
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const voidReturnTransIt = (voidReturnTransactionId) => {
    const data = {
      transaction_id: voidReturnTransactionId,
    };

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "void_return_using_transit", data, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.VoidResponse.status == "PASS") {
          setSuccess(true);
          setSuccessMsg(t('msg_return_voided_successfully'));
          const data = { current_page: currentPages, rowPerPage: rowPerPage };
          getTransList(data);
          setVoidReturnTransId("");
          setStartDate(new Date());
          setEndDate(new Date());
        } else {
          setError(true);
          setErrorMsg(response.data.VoidResponse.responseMessage);
        }
      })
      .catch((error) => {
        setError(true);
        setErrorMsg(t('msg_void_return_failed'));
        return error;
      });
  };
  /*
    @function for search transaction list
  */
  const handleSearch = () => {
    setTableData(false);
    var sDate = new Date(startDate);
    if (endDate === null || endDate === "" || endDate === undefined) {
      setEndDate(new Date());
      var eDate = new Date(new Date());
    } else {
      var eDate = new Date(endDate);
    }
    const idArray = filterLocation.map(eachLoc => eachLoc.id);

    const selectedFilterTenderType  = filterTenderType.map(value => {
      switch (value) {
        case t('lbl_cash'):
          return 'Cash';
        case t('lbl_check'):
          return 'Check';
        case t('lbl_debit'):
          return 'Debit';
        case t('lbl_credit'):
          return 'Credit';
        case t('lbl_ebt'):
          return 'EBT';
        case t('lbl_ath'):
          return 'ATH';        
        default:
          return value;
      }
    });
    
    const selectedFilterPaymentRes = filterPaymentRes.map(value => {
      switch (value) {
        case t('lbl_approved'):
          return 'Approved';
        case t('lbl_declined'):
          return 'Declined';
        default:
          return value;
      }
    });

    const selectedFiltercardType = filtercardType.map((value) => {
      switch (value) {
        case t('lbl_value_AMEX'):
          return "AMEX";
        case t('lbl_ath'):
          return "ATH";
        case t('lbl_discover'):
          return "Discover";
        case t('lbl_discover_cup'):
          return "Discover CUP";
        case t('lbl_ebt'):
          return "EBT";
        case t('lbl_jcb'):
          return "JCB";
        case t('lbl_master_card'):
          return "MasterCard";
        case t('lbl_visa'):
          return "Visa";
        case t('lbl_other'):
          return "Other";
        default:
          return value; // If no match, return the value as it is
      }
    });

    const selectedFilterTransactionType= filterTransactionType.map((value) => {
      switch (value) {
        case t('lbl_sale'):
          return "Sale";
        case t('lbl_void'):
          return "Void";
        case t('lbl_refund_btn'):
          return "Refund";
        case t('lbl_pending_refund'):
          return "Pending Refund";
        case t('lbl_all'):
          return "All";
        default:
          return value; // Return the value as it is if no match
      }
    });
    
    

    const data = {
      startDate: sDate.toISOString().slice(0, 10),
      endDate: eDate.toISOString().slice(0, 10),
      current_page: currentPages,
      rowPerPage: rowPerPage,
      filterAmtFrom: filterAmtFrom,
      filterAmtTo: filterAmtTo,
      filterTenderType: selectedFilterTenderType,
      filtercardType: selectedFiltercardType,         
      filterTransactionType: selectedFilterTransactionType,
      filterPaymentRes: selectedFilterPaymentRes,
      card_number: lastFour,
      filterLocation:idArray,
    };  
    getTransList(data);
  };

  /*
    @function Download receipt in pdf format
  */
  const downloadPdf = (ecrNum) => {
    const data = {
      ECRRefNum: ecrNum,
      IsWebPDF: "1",
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .get(window.apiUrl + "receipt/" + ecrNum + "?IsWebPDF=1", data, {
        headers: headers,
      })
      .then((response) => {
        let htmlStr = response.data;

        htmlStr = htmlStr.replace(
          '<body style="background-color:white;">',
          '<body style="background-color:white;"><div style="word-spacing: 3px;">'
        );
        htmlStr = htmlStr.replace("</body>", "</div></body>");

        /*let doc = new jsPDF("portrait", 'pt', 'a4');

      doc.html(htmlStr, {
        callback: (doc) => {
          doc.save('receipt_' + ecrNum + '.pdf');
        }, jsPDF: doc
      });*/
        var html = htmlToPdfmake(htmlStr);

        const documentDefinition = { content: html };
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake
          .createPdf(documentDefinition)
          .download("receipt_" + ecrNum + ".pdf");

        setEcrRefNo(0);
      })
      .catch((error) => {
        setError(true);
        setErrorMsg(t('msg_fail_to_download'));
        return error;
      });
  };

  /*
    @function Sent receipt in sms
  */
  const sendSMSReceipt = () => {
    const data = {
      number: sendNumber,
      ecrRefNum: ecrRefNo,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "sms", data, {
        headers: headers,
      })
      .then((response) => {
        setOpenSMSReceipt(false);
        setSendNumber("");
        setEcrRefNo(0);
        setSuccess(true);
        setSuccessMsg(t('msg_sms_send_successfully'));
      })
      .catch((error) => {
        setError(true);
        setErrorMsg(error.response.data.msg);
        return error;
      });
  };

  /*
    @function for handle how many record display in single page
  */
  const handelPagination = (e, v, rows) => {
    setTableData(false);
    if (startDate) {
      var sDate = new Date(startDate);
      if (endDate === null || endDate === "" || endDate === undefined) {
        setEndDate(new Date());
        var eDate = new Date(new Date());
      } else {
        var eDate = new Date(endDate);
      }
      const data = {
        startDate: sDate.toISOString().slice(0, 10),
        endDate: eDate.toISOString().slice(0, 10),
        current_page: v,
        rowPerPage: rows,
        filterAmtFrom: filterAmtFrom,
        filterAmtTo: filterAmtTo,
        filterTenderType: filterTenderType,
        filtercardType: filtercardType,
        filterLocation: filterLocation,
        
        filterTransactionType: filterTransactionType,
        filterPaymentRes: filterPaymentRes,
      };
      getTransList(data);
    } else {
      const data = { current_page: v, rowPerPage: rows };
      getTransList(data);
    }
  };

  /*
    @function for select multiple devices on filter
  */
  
 

  /*
    @function for select multiple tender types on filter
  */
  const handleTenderTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTenderType(typeof value === "string" ? value.split(",") : value);
  };

  /*
    @function for select multiple Employees on filter
  */
  const handleLocationChange = (event, selectedVal) => {    
    setFilterLocation(selectedVal);
  };

  /*
    @function for select multiple card types on filter
  */
  const handleCardTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterCardType(typeof value === "string" ? value.split(",") : value);
  };

  /*
    @function for select multiple transaction types on filter
  */
  const handleTransTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTransactionType(
      typeof value === "string" ? value.split(",") : value
    );
  };

  /*
    @function for select multiple payment results on filter
  */
  const handlePaymentResChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterPaymentRes(typeof value === "string" ? value.split(",") : value);
  };

  /*
    @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  const handelVoidTransaction = () => {
    setTableData(false);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };

    const data = {
      transaction_id: transactionId,
      location_id: localStorage.getItem("location_id"),
      parent_id: localStorage.getItem("parent_id"),
    };
    axios
      .post(window.apiUrl + "void_trans_using_transit", data, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.VoidResponse.status == "PASS") {
          setSuccess(true);
          setSuccessMsg(t('msg_transaction_voided_successfully'));
          const data = { current_page: currentPages, rowPerPage: rowPerPage };
          getTransList(data);
        } else {
          setError(true);
          setErrorMsg(response.data.VoidResponse.responseMessage);
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const handelSendEmailRequest = () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };

    const data = {
      transaction_id: transactionId,
      location_id: localStorage.getItem("location_id"),
      parent_id: localStorage.getItem("parent_id"),
      serial_number: serial_number,
      amount: kushkiManualRefundAmount,
    };
    axios
      .post(window.apiUrl + "billPocket_manual_refund_mail", data, {
        headers: headers,
      })
      .then((response) => {
        
          setSuccess(true);
          setSuccessMsg(t('msg_refund_request_send_successfully'));
          const data = { current_page: currentPages, rowPerPage: rowPerPage };
          getTransList(data);
       
      })
      .catch((error) => {
        return error;
      });
  };


  const handelManualRefundDataUpdate = () => {
    // alert();
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    if (manualRefundTransactionId != "" && manualRefundAuthCode != "" && manualRefundAmount != "" && manualRefundNotes != "") {
      console.log(manualRefundAmount +"   "+ availableAmountForManualRefund)
      if (parseFloat( manualRefundAmount) <= parseFloat(availableAmountForManualRefund)) {
        const data = {
          manual_refund_primary_id: manualRefundPrimaryId,
          manual_refund_transaction_id: manualRefundTransactionId,
          manual_refund_auth_code: manualRefundAuthCode,
          manual_refund_amount: manualRefundAmount,
          parent_id: localStorage.getItem("parent_id"),
          location_id: localStorage.getItem("location_id"),
          manual_refund_notes: manualRefundNotes,
        };

        axios
          .post(window.apiUrl + "manual_refund_data_update", data, {
            headers: headers,
          })
          .then((response) => {
            console.log(response);
            if (response.data.message == "success") {
              setSuccess(true);
              setSuccessMsg(t('msg_manual_refund_successfull'));
              setManualRefundDataAskPopup(false);
              setManualRefundTransactionId("");
              setManualRefundAuthCode("");
              setManualRefundAmount("");
              setManualRefundNotes("");
              setManualRefundPrimaryId("");
              const data = { current_page: currentPages, rowPerPage: rowPerPage };
              getTransList(data);
            }
            else {
              setError(true);
              setErrorMsg(t('msg_manual_refund_failed'));
              setManualRefundDataAskPopup(false);
              setManualRefundTransactionId("");
              setManualRefundAuthCode("");
              setManualRefundAmount("");
              setManualRefundNotes("");
              setManualRefundPrimaryId("");
            }
          })
          .catch((error) => {
            return error;
          });
      }
      else {
        setError(true);
        setErrorMsg(t('msg_entered_amount_is_larger_than_original_amount'));
      }
    }
    else {
      setError(true);
      setErrorMsg(t('msg_please_enter_all_details'));
    }




  };

  const handelReturnTransaction = (refund_type) => {
    setTableData(false);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    if (devicePlatform == "Billpocket") {
      const data = {
        refund_type: refund_type,
        id: id,
        serial_number: serial_number,
        parent_id: parentID,
        location_id: locationID,
        transaction_id: transactionId,
        amount: partialRefundAmount,
        tip: tip,
      };
      if (refund_type === "refund_items") {
        data.refund_items_id = retrunItemId;
        data.amount = String(selectRefundAmt.toFixed(2));
      }
      axios
        .post(window.apiUrl + "return_trans_using_billpocket", data, {
          headers: headers,
        })
        .then((response) => {
          console.log(response);

          if (response.data.message.statusInfo !== "PASS") {
            if (response.data.message.statusInfo != undefined) {
              setError(true);
              setErrorMsg(response.data.message.statusInfo);
            } else if (response.data.message != undefined) {
              setError(true);
              setErrorMsg(response.data.message);
            }

            setKushkiManualRefundAmount(partialRefundAmount);
            setManualRefundRequestPopup(true);

          } else {
            setSuccess(true);
            setSuccessMsg(t('msg_refunded_successfully'));
          }
          const data = { current_page: 1, rowPerPage: rowPerPage };
          getTransList(data);
        })
        .catch((error) => {
          return error;
        });
    } else {
      const data = {
        refund_type: refund_type,
        id: id,
        serial_number: serial_number,
        parent_id: localStorage.getItem("parent_id"),
        location_id: localStorage.getItem("location_id"),
        transaction_id: transactionId,
        amount: partialRefundAmount.replace(".", ""),
        tip: tip,
      };
      if (refund_type === "refund_items") {
        data.refund_items_id = retrunItemId;
        data.amount = String(selectRefundAmt.toFixed(2)).replace(".", "");
      }
      axios
        .post(window.apiUrl + "return_trans_using_transit", data, {
          headers: headers,
        })
        .then((response) => {
          // console.log(response.data);
          // alert(JSON.stringify(response.data));
          if (response.data.status !== "PASS") {
            setError(true);
            setErrorMsg(response.data.responseMessage);
          } else {
            setSuccess(true);
            setSuccessMsg(t('msg_refunded_successfully'));
          }
          const data = { current_page: 1, rowPerPage: rowPerPage };
          getTransList(data);
        })
        .catch((error) => {
          return error;
        });
    }
  };
  /*
    @function for generate and download csv file
  */
  const csvLink = useRef();
  const handelExport = () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    var sDate = new Date(startDate);

    if (endDate === null || endDate === "" || endDate === undefined) {
      //Check end date was null,blank or undefined
      setEndDate(new Date());
      var eDate = new Date(new Date());
    } else {
      var eDate = new Date(endDate);
    }

    const data = {
      startDate: sDate.toISOString().slice(0, 10),
      endDate: eDate.toISOString().slice(0, 10),
      filterAmtFrom: filterAmtFrom,
      filterAmtTo: filterAmtTo,
      filterTenderType: filterTenderType,
      filtercardType: filtercardType,
      filterLocation: filterLocation,      
      filterTransactionType: filterTransactionType,
      filterPaymentRes: filterPaymentRes,
      card_number: lastFour,
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "get_incorrect_transaction_export", data, {
        headers: headers,
      })
      .then((response) => {       
        const exportArr = [];
        exportArr.push([
          t('lbl_date'),
          "ECR#",
          t('lbl_Employee'),
          t('lbl_status'),
          t('lbl_tender_type'),
          t('lbl_Transaction_Amount'),
          t('lbl_Tip_Amount'),
          t('lbl_total_amount'),
          t('lbl_card_type'),
          t('lbl_entry_type'),
          t('lbl_transaction_type'),
          t('lbl_card_number'),
          t('lbl_Card_Holder_Name'),
          t('lbl_device'),
          t('lbl_Device_Nick_Name'),
          t('lbl_Customer_Name'),
          t('lbl_Table_Number'),
        ]);
        response.data.transData.forEach((eachTransaction) => {
          var eachAmt = "";
          eachAmt = Helper.viewCurrencyFormat(
            parseFloat(eachTransaction.amount)
          );
          

          if (eachTransaction.response_code !== "000000") {
            //Check transaction response
            var transStatusExport = "Declined";
          } else if (eachTransaction.transaction_type === "RETURN") {
            //Check transaction type was return
            var transStatusExport = "Return";
          } else {
            var transStatusExport = "Success";
          }
          var transAmt = Helper.viewCurrencyFormat(
            parseFloat(eachTransaction.amount - eachTransaction.tip_amt)
          );
          var marchantName = "";
          if (eachTransaction.emp_id != 0) {
            //Check if employee id was not 0
            empDropDownVal.map((eachEmp) => {
              if (eachTransaction.emp_id == eachEmp.user_id) {
                marchantName = eachEmp.employee_name;
              }
            });
          } else {
            // if (
            //   settingData.settingData.user_id != undefined &&
            //   eachTransaction.user_id === settingData.settingData.user_id
            // ) {
            //   marchantName = settingData.settingData.name;
            // }
          }
          exportArr.push([
            eachTransaction.device_created_at,
            eachTransaction.ecrref_num,
            marchantName,
            transStatusExport,
            eachTransaction.payment_method,
            transAmt,
            Helper.viewCurrencyFormat(parseFloat(eachTransaction.tip_amt)),
            eachAmt,
            eachTransaction.card_type,
            eachTransaction.card_entry_type,
            eachTransaction.is_void != "0" ? "VOID" : "SALE",
            eachTransaction.card_number,
            eachTransaction.card_holder_name,
            "SN:" + eachTransaction.serial_number,
             "",
            eachTransaction.customer_name,
            eachTransaction.table_number,
          ]);
        });
        setCsvData(exportArr);
        csvLink.current.link.click();
        setExportButtonLabel(t('lbl_export'));
      })
      .catch((error) => {
        return error;
      });
  };

  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_incorrect_billing_amount')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                  <GridItem xs={4} sm={12} md={12}>
                  <center>
                    <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                    </h3>
  
                  </center>
                  <div id="transitInfo"></div>
                  </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  if (!tableData) {
    //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_incorrect_billing_amount')}</h4>
              <p className={classes.cardCategoryWhite}>
                {/* Here is a subtitle for this table */}
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>{t('msg_loading_transaction')}</h3>
                <CircularProgress id="loginProgress"></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      {csvData ? (
        <CSVLink
          data={csvData}
          ref={csvLink}
          target="_blank"
          filename={"export-trans.csv"}
        ></CSVLink>
      ) : (
        ""
      )}
      {/* Filter Popup start */}
      <Dialog
        open={filterDailogOpen}
        keepMounted
        onClose={() => { }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"More Filters"}
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={() => { }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            onClick={() => { }}
            color="secondery"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_apply')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Filter Popup end  */}

      {/* 
        Display error message
      */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={error}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* 
        Display success message
      */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={sucess}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      {/* Send email receipt start */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openSendReceipt}
        onClose={() => {
          setOpenSendReceipt(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t('lbl_send_email_receipt')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t('lbl_enter_email_address')}
            type="email"
            onChange={(e) => setSendEmail(e.target.value)}
            value={sendEmail}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setEcrRefNo(0);
              setSendEmail("");
              setOpenSendReceipt(false);
            }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            id="btnGreenEdit"
            onClick={() => {
              sendEmailReceipt();
            }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_send')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Send email receipt end */}

      {/* Void Transaction confirm start */}
      <Dialog
        open={openVoidPopup}
        onClose={() => {
          setOpenSMSReceipt(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('lbl_void_tans_confirm_msg')}
        </DialogTitle>

        <DialogActions>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setTransactionId(0);
              setLocationID(0);
              setParentID(0);
              setOpenVoidPopup(false);
            }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            id="btnGreenAdd"
            onClick={() => {
              handelVoidTransaction();
              setOpenVoidPopup(false);
            }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_yes')}
          </Button>
        </DialogActions>
      </Dialog>

      {/*  Void Transaction confirm end  */}

      {/* Return Transaction confirm start */}
      <Dialog
        open={openReturnPopup}
        onClose={() => {
          setOpenReturnPopup(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('lbl_refund_tans_confirm_msg')}
        </DialogTitle>

        <DialogActions>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setTransactionId(0);
              setLocationID(0);
              setParentID(0);
              setOpenReturnPopup(false);
            }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            id="btnGreenAdd"
            onClick={() => {
              handelReturnTransaction("Full Refund");
              setOpenReturnPopup(false);
            }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
             {t('lbl_yes')}
          </Button>
        </DialogActions>
      </Dialog>

      {/*void Return confirm start */}
      <Dialog
        open={voidReturnPopup}
        onClose={() => {
          setVoidReturnPopup(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('lbl_void_refund_tans_confirm_msg')}
        </DialogTitle>

        <DialogActions>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setVoidReturnTransId("");
              setVoidReturnPopup(false);
            }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            id="btnGreenAdd"
            onClick={() => {
              voidReturnTransIt(voidReturnTransId);
              setVoidReturnPopup(false);
            }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_yes')}
          </Button>
        </DialogActions>
      </Dialog>
      {/*void Return confirm end */}

      <Dialog
        maxWidth={"md"}
        open={openReturnTypePopup}
        onClose={() => {
          setOpenReturnTypePopup(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('lbl_select_refund_type')}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <div>
              {returnType === "not_refund" ? (
                <Button
                  onClick={() => {
                    setOpenReturnTypePopup(false);
                    setOpenReturnPopup(true);
                  }}
                  id="btnGreenAdd"
                  color="primary"
                  style={{
                    width: "100%",
                    backgroundColor: "#ffa726",
                    color: "#FFFFFF",
                    marginRight: "20px",
                    whiteSpace:"nowrap"
                  }}
                >
                  {" "}
                  {t('lbl_full_refund')}{" "}
                </Button>
              ) : (
                <Button
                  id="grayBgBtn"
                  color="primary"
                  style={{
                    cursor: "not-allowed",
                    width: "100%",
                    backgroundColor: "#ffa726",
                    color: "#FFFFFF",
                    marginRight: "20px",
                    whiteSpace:"nowrap"
                  }}
                >
                  {" "}
                  {t('lbl_full_refund')}{" "}
                </Button>
              )}
            </div>
            <div>
              {returnType === "not_refund" || returnType === "partial" ? (
                <Button
                  onClick={() => {
                    setOpenReturnTypePopup(false);
                    setOpenPartialReturnPopup(true);
                  }}
                  id="btnGreenAdd"
                  color="primary"
                  style={{
                    width: "100%",
                    backgroundColor: "#ffa726",
                    color: "#FFFFFF",
                    whiteSpace:"nowrap"
                  }}
                >
                  {t('lbl_partial_refund')}
                </Button>
              ) : (
                <Button
                  id="grayBgBtn"
                  color="primary"
                  style={{
                    cursor: "not-allowed",
                    width: "100%",
                    backgroundColor: "#ffa726",
                    color: "#FFFFFF",
                    marginRight: "20px",
                    whiteSpace:"nowrap"
                  }}
                >
                  {" "}
                  {t('lbl_partial_refund')}
                </Button>
              )}
            </div>
            <div>
              {returnType === "not_refund" || returnType === "item" ? (
                <Button
                  onClick={() => {
                    setOpenReturnTypePopup(false);
                    setOpenItemRefundPopup(true);
                    getTransactionItemList();
                  }}
                  id="btnGreenAdd"
                  color="primary"
                  style={{
                    width: "100%",
                    backgroundColor: "#ffa726",
                    color: "#FFFFFF",
                    whiteSpace:"nowrap"
                  }}
                >
                  {t('lbl_itemwise_refund')}
                </Button>
              ) : (
                <Button
                  id="grayBgBtn"
                  color="primary"
                  style={{
                    cursor: "not-allowed",
                    width: "100%",
                    backgroundColor: "#ffa726",
                    color: "#FFFFFF",
                    marginRight: "20px",
                    whiteSpace:"nowrap"
                  }}
                >
                  {" "}
                  {t('lbl_itemwise_refund')}
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Dialog
        open={openPartialReturnPopup}
        onClose={() => {
          setOpenPartialReturnPopup(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('msg_how_would_you_like_to_refund')}
        </DialogTitle>
        <DialogContent>
          <div>
            <h5 className={classes.partialrefundamnt}>
              {t('lbl_remaining_refund_amount')}: ${availableRefund}
            </h5>
            <FormControl sx={{ width: "100%" }}>
              {/* Amount from textfield */}
              <TextField
                id="outlined-basic"
                variant="outlined"
                margin="full"
                onChange={(e) => validatePartialRefundValue(e)}
                value={partialRefundAmount}
                label={t('lbl_enter_amount')}
                style={{ width: "100%" }}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className={classes.partialrefundpopupbuttonsection}>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setOpenPartialReturnPopup(false);
            }}
            color="primary"
            style={{
              width: "100%",
              backgroundColor: "#ffa726",
              color: "#FFFFFF",
            }}
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            id="btnGreenAdd"
            onClick={() => {
              setOpenPartialReturnPopup(false);
              handelReturnTransaction("Partial Refund");
              setOpenPartialReturnPopup(false);
            }}
            color="primary"
            style={{
              width: "100%",
              backgroundColor: "#ffa726",
              color: "#FFFFFF",
            }}
          >
            {t('lbl_partial_refund')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* manual refund request popup */}

      <Dialog
        open={manualRefundRequestPopup}
        onClose={() => {
          setManualRefundRequestPopup(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('msg_confirm_manual_refund')}
        </DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: "100%" }}>
            {/* Amount from textfield */}
            <TextField
              id="outlined-basic"
              variant="outlined"
              margin="full"
              onChange={(e) => validateKushkirefundAmnt(e)}
              value={kushkiManualRefundAmount}
              label={t('lbl_enter_amount')}
              style={{ width: "100%" }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setManualRefundRequestPopup(false);
            }}
            color="primary"
            style={{
              width: "100%",
              backgroundColor: "#ffa726",
              color: "#FFFFFF",
            }}
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            id="btnGreenAdd"
            onClick={() => {
              setManualRefundRequestPopup(false);
              setRefundRequestSendPopup(true);
              handelSendEmailRequest();
            }}
            color="primary"
            style={{
              width: "100%",
              backgroundColor: "#ffa726",
              color: "#FFFFFF",
            }}
          >
            {t('lbl_send_req')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* request sended popup*/}
      <Dialog
        open={refundRequestSendPopup}
        onClose={() => {
          setRefundRequestSendPopup(false);
        }}
        aria-labelledby="form-dialog-title"
      >
          
        <DialogContent>
          <div style={{textAlign:"right"}}>
            <span id="customMadePopupCloseBtn"
                    onClick={() => {
                      setRefundRequestSendPopup(false);
                    }}
            >X</span>
          </div>
          <h5 style={{marginTop:"10px"}}>
            <b>
              {t('msg_manual_refund_res')}
            </b>
          </h5>
        </DialogContent>
        <DialogActions>
          {/* <Button
            id="btnGreyCancel"
            onClick={() => {
              setRefundRequestSendPopup(false);
            }}
            color="primary"
            style={{
              width: "100%",
              backgroundColor: "#ffa726",
              color: "#FFFFFF",
              marginBottom: "20px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            Ok
          </Button>*/}
        </DialogActions>
      </Dialog>


      {/* manual refund ask data popup */}

      <Dialog
        open={manualRefundDataAskPopup}
        onClose={() => {
          setManualRefundRequestPopup(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('lbl_manual_refund')}
        </DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: "100%" }}>
            {/* Amount from textfield */}
            <TextField
              id="outlined-basic"
              variant="outlined"
              margin="full"
              onChange={(e) => setManualRefundTransactionId(e.target.value)}
              value={manualRefundTransactionId}
              label={t('lbl_transaction_id')}
              style={{ width: "100%" }}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", marginTop: "10px" }}>
            {/* Amount from textfield */}
            <TextField
              id="outlined-basic"
              variant="outlined"
              margin="full"
              onChange={(e) => setManualRefundAuthCode(e.target.value)}
              value={manualRefundAuthCode}
              label={t('lbl_auth_code')}
              style={{ width: "100%" }}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", marginTop: "10px" }}>
            {/* Amount from textfield */}
            <TextField
              id="outlined-basic"
              variant="outlined"
              margin="full"
              onChange={(e) => validateManualRefundAmount(e)}
              value={manualRefundAmount}
              label={t('lbl_refund_amt')}
              style={{ width: "100%" }}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", marginTop: "10px" }}>
            {/* Amount from textfield */}
            <TextField
              id="outlined-basic"
              variant="outlined"
              margin="full"
              onChange={(e) => setManualRefundNotes(e.target.value)}
              value={manualRefundNotes}
              label={t('lbl_refund_note')}
              style={{ width: "100%" }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setManualRefundDataAskPopup(false);

            }}
            color="primary"
            style={{
              width: "100%",
              backgroundColor: "#ffa726",
              color: "#FFFFFF",
            }}
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            id="btnGreenAdd"
            onClick={() => {
              handelManualRefundDataUpdate();
            }}
            color="primary"
            style={{
              width: "100%",
              backgroundColor: "#ffa726",
              color: "#FFFFFF",
            }}
          >
            {t('lbl_refund_btn')}
          </Button>
        </DialogActions>
      </Dialog>



      {/* Transaction request and response body show dailog */}
      <Dialog
        fullScreen={transRequest == "{}" ? "" : true}
        style={{ margin: "60px" }}
        classes={{ paper: classes.paper }}
        open={openRequestResponsePopup}
        onClose={() => {
          setOpenPartialReturnPopup(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        {/* <DialogTitle id="form-dialog-title">Request And Response Body</DialogTitle> */}
        <DialogContent id="logDialogContent">
          <GridContainer id="logSection">
            {transRequest == "{}" ? (
              <GridItem xs={12} sm={12} md={12}>
                <CircularProgress id="loginProgress"></CircularProgress>
              </GridItem>
            ) : (
              <>
                <GridItem xs={12} sm={12} md={6} style={{ height: "100%" }}>
                  <h5
                    style={{ marginTop: "0px" }}
                    className={classes.partialrefundamnt}
                  >
                    {t('lbl_request')}
                  </h5>
                  <div
                    style={{
                      border: "1px solid gray",
                      borderRadius: "10px",
                      height: "90%",
                      overflowY: "scroll",
                    }}
                  >
                    {/* <pre>{JSON.stringify(JSON.parse('{"transaction_no":"2","payment_method":"CREDIT","is_missing_flag":0,"edc_type":"CREDIT","transaction_id":"4519291228","transaction_token":null,"pin_status":"PIN Bypassed","card_entry_type":"Chip","card_number":"0206","card_type":"VISA","ECRRefNum":"1704098413977","expDate":"0830","cardHolderName":"HARDIK ADROJA","transaction_type":"SALE","auth_code":"059867","response_code":"000000","response_text":"OK","amount":"2","items":[{"itemName":"Quick Item","itemQty":"1","ItemPrice":"0.02","itemId":0,"base_price":0.02}],"taxes":[{"tax1":"1111","tax2":"222"}],"flatDiscount":[],"percentageDiscount":[],"nonCashAdj":0,"percentage":2,"itemName":"[Quick Item]","itemId":"[0]","itemTaxes":"[[{}]]","subtotal":0.0200000000000000004163336342344337026588618755340576171875,"serial_number":"1850517109","customer_name":null,"table_number":null,"modifierArray":[[[]]],"device_created_at":"2024-01-01 03:40:28","tipData":{"tipAmt":"0.00","ECRRefNum":"1704098413977","tipName":"No Tip"},"parent_id":"49","location_id":"1","is_void":null,"amount_due":"0.00","offline_id":"1850517109_1704098428"}'), null, 2)}</pre>                 */}
                    <pre style={{ whiteSpace: "pre-wrap", padding: "10px" }}>
                      {isJsonObjReq
                        ? JSON.stringify(transRequest, null, 2)
                        : transRequest}
                    </pre>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} style={{ height: "100%" }}>
                  <h5
                    style={{ marginTop: "0px" }}
                    className={classes.partialrefundamnt}
                  >
                    {t('lbl_res')}
                  </h5>
                  <div
                    style={{
                      border: "1px solid gray",
                      borderRadius: "10px",
                      height: "90%",
                      overflowY: "scroll",
                    }}
                  >
                    <pre style={{ whiteSpace: "pre-wrap", padding: "10px" }}>
                      {isJsonObjRes
                        ? JSON.stringify(transResponse, null, 2)
                        : transResponse}
                    </pre>
                    {/* {transResponse} */}
                  </div>
                </GridItem>
              </>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.partialrefundpopupbuttonsection}>
          <Button
            id="btnRedDelete"
            onClick={() => {
              setOpenRequestResponsePopup(false);
              setTransRequest("{}");
              setTransResponse("{}");
            }}
            color="primary"
            style={{
              width: "100%",
            }}
          >
            {t('lbl_close')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        id="itemRefundDialog"
        open={openItemRefundPopup}
        onClose={() => {
          setOpenItemRefundPopup(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t('lbl_item_refund')}</DialogTitle>
        <DialogContent>
          <FormGroup>{orderItem}</FormGroup>
        </DialogContent>
        <DialogActions className={classes.partialrefundpopupbuttonsection}>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setOpenItemRefundPopup(false);
            }}
            color="primary"
            style={{
              width: "100%",
              backgroundColor: "#ffa726",
              color: "#FFFFFF",
            }}
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            id="btnGreenAdd"
            onClick={(e) => {
              setOpenItemRefundPopup(false);
              handelReturnTransaction("refund_items");
            }}
            color="primary"
            style={{
              width: "100%",
              backgroundColor: "#ffa726",
              color: "#FFFFFF",
            }}
          >
            {t('lbl_refund_btn')}
          </Button>
        </DialogActions>
      </Dialog>

      {/*  Return Transaction confirm end  */}

      {/* Send SMS receipt start */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openSMSReceipt}
        onClose={() => {
          setOpenSMSReceipt(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t('lbl_send_sms')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="25px"
            id="name"
            label={t('lbl_enter_mo')}
            type="text"
            onChange={(e) => setSendNumber(e.target.value)}
            value={Helper.formatPhoneNumber(sendNumber)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="btnGreyCancel"
            onClick={() => {
              setEcrRefNo(0);
              setSendNumber("");
              setOpenSMSReceipt(false);
            }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            id="btnGreenAdd"
            onClick={() => {
              sendSMSReceipt();
            }}
            color="primary"
            style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
          >
            {t('lbl_send')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Send SMS receipt end */}

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_incorrect_billing_amount')} </h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {/* Select start date and end date */}
              <GridItem xs={12} sm={12} md={3}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <span style={{ paddingBottom: "5px" }}>{t('lbl_start_date')}</span>
                  <DatePicker
                    className={classes.datePickerTextBox}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <span style={{ paddingBottom: "5px" }}>{t('lbl_end_date')}</span>
                  <DatePicker
                    className={classes.datePickerTextBox}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={12} md={3} style={{ display: "flex" }}>
                <GridItem
                  id="gridFromTo"
                  xs={12}
                  sm={12}
                  md={6}
                  style={{ padding: "0 5px !important" }}
                >
                  <FormControl
                    id="transAmountFrom"
                    sx={{ m: 1, width: "100%" }}
                  >
                    {/* Amount from textfield */}
                    <span style={{ paddingBottom: "5px" }}>{t('lbl_amount')}</span>
                    <TextField
                      type="number"
                      id="outlined-basic"
                      className="transAmountFromLabel"
                      variant="outlined"
                      margin="full"
                      label={t('lbl_from')}
                      onChange={(e) => {
                        setFilterAmtFrom(e.target.value);
                      }}
                      value={filterAmtFrom}
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem
                  id="gridFromTo"
                  xs={12}
                  sm={12}
                  md={6}
                  style={{ padding: "0px 5px !important" }}
                >
                  <FormControl id="transToFrom" sx={{ m: 1, width: "100%" }}>
                    {/* Amount to textfield */}
                    <span style={{ paddingBottom: "5px" }}>{t('lbl_amount')}</span>
                    <TextField
                      type="number"
                      id="outlined-basic"
                      className="transAmountToLabel"
                      variant="outlined"
                      label={t('lbl_to')}
                      onChange={(e) => {
                        setFilterAmtTo(e.target.value);
                      }}
                      value={filterAmtTo}
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div style={{ height: "30px" }}>&nbsp;</div>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Amount to textfield */}
                  <TextField
                    type="number"
                    id="outlined-basic"
                    variant="outlined"
                    label={t('lbl_card_number')}
                    onChange={(e) => {
                      setLastFour(e.target.value);
                    }}
                    value={lastFour}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <div style={{ height: "25px" }}>&nbsp;</div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Dropdown for select tender types */}
                  <InputLabel id="demo-simple-select-helper-label">
                    {t('lbl_tender')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: "100%" }}
                    multiple
                    value={filterTenderType}
                    onChange={handleTenderTypeChange}
                    input={<OutlinedInput label={t('lbl_tender')} />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="Cash" value={t('lbl_cash')}>
                      <Checkbox
                        checked={filterTenderType.indexOf(t('lbl_cash')) > -1}
                      />
                      {t('lbl_cash')}
                    </MenuItem>

                    <MenuItem key="Check" value={t('lbl_check')}>
                      <Checkbox
                        checked={filterTenderType.indexOf(t('lbl_check')) > -1}
                      />
                      {t('lbl_check')}
                    </MenuItem>

                    <MenuItem key="Debit" value={t('lbl_debit')}>
                      <Checkbox
                        checked={filterTenderType.indexOf(t('lbl_debit')) > -1}
                      />
                      {t('lbl_debit')}
                    </MenuItem>

                    <MenuItem key="Credit" value={t('lbl_credit')}>
                      <Checkbox
                        checked={filterTenderType.indexOf(t('lbl_credit')) > -1}
                      />
                      {t('lbl_credit')}
                    </MenuItem>

                    <MenuItem key="EBT" value={t('lbl_ebt')}>
                      <Checkbox
                        checked={filterTenderType.indexOf(t('lbl_ebt')) > -1}
                      />
                      {t('lbl_ebt')}
                    </MenuItem>

                    <MenuItem key="ATH" value={t('lbl_ath')}>
                      <Checkbox
                        checked={filterTenderType.indexOf(t('lbl_ath')) > -1}
                      />
                      {t('lbl_ath')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Dropdown for select card types */}
                  <InputLabel id="demo-simple-select-helper-label">
                    {t('lbl_card_type')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: "100%" }}
                    multiple
                    value={filtercardType}
                    onChange={handleCardTypeChange}
                    input={<OutlinedInput label={t('lbl_card_type')} />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="AMEX" value={t('lbl_value_AMEX')}>
                      <Checkbox checked={filtercardType.indexOf(t('lbl_value_AMEX')) > -1} />
                     {t('lbl_american_express')}
                    </MenuItem>

                    <MenuItem key="ATH" value={t('lbl_ath')}>
                      <Checkbox checked={filtercardType.indexOf(t('lbl_ath')) > -1} />
                      {t('lbl_ath')}
                    </MenuItem>

                    <MenuItem key="Discover" value={t('lbl_discover')}>
                      <Checkbox
                        checked={filtercardType.indexOf(t('lbl_discover')) > -1}
                      />
                      {t('lbl_discover')}
                    </MenuItem>

                    <MenuItem key="Discover CUP" value={t('lbl_discover_cup')}>
                      <Checkbox
                        checked={filtercardType.indexOf(t('lbl_discover_cup')) > -1}
                      />
                      {t('lbl_discover_cup')}
                    </MenuItem>

                    <MenuItem key="EBT" value={t('lbl_ebt')}>
                      <Checkbox checked={filtercardType.indexOf(t('lbl_ebt')) > -1} />
                      {t('lbl_ebt')}
                    </MenuItem>

                    <MenuItem key="JCB" value={t('lbl_jcb')}>
                      <Checkbox checked={filtercardType.indexOf(t('lbl_jcb')) > -1} />
                      {t('lbl_jcb')}
                    </MenuItem>

                    <MenuItem key="MasterCard" value={t('lbl_master_card')}>
                      <Checkbox
                        checked={filtercardType.indexOf(t('lbl_master_card')) > -1}
                      />
                      {t('lbl_master_card')}
                    </MenuItem>

                    <MenuItem key="Visa" value={t('lbl_visa')}>
                      <Checkbox checked={filtercardType.indexOf(t('lbl_visa')) > -1} />
                      {t('lbl_visa')}
                    </MenuItem>

                    <MenuItem key="Other" value={t('lbl_other')}>
                      <Checkbox
                        checked={filtercardType.indexOf(t('lbl_other')) > -1}
                      />
                      {t('lbl_other')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
               {/* <div style={{ height: "10px" }}>&nbsp;</div> */}
                  {/* Dropdown for select employees */}
                  <div id="multiMerchantDiv">
                  <Autocomplete
                      multiple                      
                      style={{ width: "100%",padding:"8.5px 4px 8.5px 5px" }}
                      id="multiMerchantNameInput"                    
                      options={locationDropDownVal}
                      disableCloseOnSelect
                      value={filterLocation}
                      getOptionLabel={(option) => option.business_name +" - " +option.merchant_id }
                      renderOption={(props, option,{ selected }) => {
                        const { key, ...optionProps } = props;
                        
                        return (
                          <li key={key} {...optionProps}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.business_name +" - " +option.merchant_id}
                          </li>
                        );
                      }}                      
                      renderInput={(params) => (
                        
                        <TextField 
                        id="multiMerchantNameInput"
                        variant="outlined" 
                        {...params} 
                        label={t('lbl_merchant_name')}
                        placeholder="search..." />
                      )}
                      onChange={handleLocationChange}
                    />                
                </div>
              </GridItem>            
            </GridContainer>

            <div style={{ height: "25px" }}>&nbsp;</div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Dropdown for transaction types */}
                  <InputLabel id="demo-simple-select-helper-label">
                    {t('lbl_transaction_type')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ width: "100%" }}
                    multiple
                    value={filterTransactionType}
                    onChange={handleTransTypeChange}
                    input={<OutlinedInput label={t('lbl_transaction_type')} />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="Sale" value={t('lbl_sale')}>
                      <Checkbox
                        checked={filterTransactionType.indexOf(t('lbl_sale')) > -1}
                      />
                      {t('lbl_sale')}
                    </MenuItem>

                    <MenuItem key="Void" value={t('lbl_void')}>
                      <Checkbox
                        checked={filterTransactionType.indexOf(t('lbl_void')) > -1}
                      />
                      {t('lbl_void')}
                    </MenuItem>
     
                    <MenuItem key="Refund" value={t('lbl_refund_btn')}>
                      <Checkbox
                        checked={filterTransactionType.indexOf(t('lbl_refund_btn')) > -1}
                      />
                      {t('lbl_refund_btn')}
                    </MenuItem>
                    
                    <MenuItem key="pending_refund" value={t('lbl_pending_refund')}>
                      <Checkbox
                        checked={filterTransactionType.indexOf(t('lbl_pending_refund')) > -1}
                      />
                      {t('lbl_pending_refund')}
                    </MenuItem>

                    <MenuItem key="All" value={t('lbl_all')}>
                      <Checkbox
                        checked={filterTransactionType.indexOf(t('lbl_all')) > -1}
                      />
                      {t('lbl_all')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* Dropdown for select payment results */}
                  <InputLabel id="payres">{t('lbl_payment_result')}</InputLabel>
                  <Select
                    labelId="payres"
                    id="payres"
                    style={{ width: "100%" }}
                    multiple
                    value={filterPaymentRes}
                    onChange={handlePaymentResChange}
                    input={<OutlinedInput label={t('lbl_payment_result')} />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="Approved" value={t('lbl_approved')}>
                      <Checkbox
                        checked={filterPaymentRes.indexOf(t('lbl_approved')) > -1}
                      />
                      {t('lbl_approved')}
                    </MenuItem>
                    <MenuItem key="Declined" value={t('lbl_declined')}>
                      <Checkbox
                        checked={filterPaymentRes.indexOf(t('lbl_declined')) > -1}
                      />
                      {t('lbl_declined')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#ffa726",
                        color: "#FFFFFF",
                        width: "100%",
                        height: "55px",
                      }}
                      onClick={handleSearch}
                      className={classes.button}
                    >
                      {t('lbl_search')}
                    </Button>
                  </FormControl>
                </center>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <Button
                      id="btnGreenEdit"
                      onClick={() => {
                        handelExport();
                        setExportButtonLabel(
                          <CircularProgress
                            style={{ color: "white" }}
                            id="loginProgress"
                          ></CircularProgress>
                        );
                      }}
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#ffa726",
                        color: "#FFFFFF",
                        height: "55px",
                      }}
                      className={classes.button}
                    >
                      {exportButtonLabel}
                    </Button>
                  </FormControl>
                </center>
              </GridItem>
            </GridContainer>
            <GridContainer></GridContainer>

            {/* Display transaction list  */}
            {devicePlatform == "Billpocket" ? (
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  t('lbl_merchant_name'),
                  t('lbl_merchant_id'),
                  t('lbl_id'),
                  t('lbl_status'),
                  t('lbl_payment_method'),
                  t('lbl_amount'),
                  t('lbl_type'),
                  t('lbl_last_4'),
                  t('lbl_card_type'),
                  t('lbl_view'),
                  t('lbl_send_receipt'),
                  t('lbl_action'),
                  localStorage.getItem("role") === "admin" &&
                    (permissionData.access_log == "1" ||
                      localStorage.getItem("admin_email") == "admin@magicpay.net")
                    ?  t('lbl_log')
                    :  "",
                    t('lbl_created_at'),
                    t('lbl_time'),
                ]}
                tableData={tableData}
              />
            ) : ""}
            {/* <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Status", "Payment Method", "Amount", "Type", "Last 4", "View", "Send Receipt", "Action", "Created at", "Time"]}
              tableData={tableData}
            /> */}
          </CardBody>
          <div>
            <div style={{ float: "left" }}>
              <FormControl sx={{ m: 1, minWidth: 120, minHeight: 40 }}>
                {/* Dropdown for select how many transaction list show in single page */}
                <InputLabel id="demo-simple-select-helper-label">
                {t('lbl_rows_per_page')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={rowPerPage}
                  label={t('lbl_rows_per_page')}
                  className={classes.rowPerPage}
                  onClick={addclass}
                  onChange={(e) => {
                    setRowPerPage(e.target.value);
                    handelPagination(e, 1, e.target.value);
                  }}
                >
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                float: "right",
                marginBottom: "10px",
                marginRight: "10px",
              }}
            >
              <Pagination
                className={classes.root}
                classes={{ ul: classes.ul }}
                style={{ color: "red" }}
                count={totalPages == 0 ? 1 : totalPages}
                defaultPage={currentPages}
                onChange={(e, v) => {
                  handelPagination(e, v, rowPerPage);
                }}
                onClick={addclass}
              />
            </div>
          </div>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
