/*
  @page employees list
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from 'axios';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }}  />  */}
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      {/* <GridToolbarFilterButton /> */}

    </GridToolbarContainer>
  );
}

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const useStyles = makeStyles(styles);

/*
  @function employees listing page logic
*/export default function employee() {
  const {t} = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pos_passcode, setPOSPasscode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [custom_id, setCustomId] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [Error, setError] = useState("");
  const [sucess, setSuccess] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [role, setrole] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [employeeDeleteDailog, setEmployeeDeleteDailog] = useState(false);
  const [employeeId, setEmployeeId] = React.useState(0);
  const [accessPage, setAccessPage] = useState(1);
  const [empEmailArr, setEmpEmailArr] = useState([]);
  const [hidePass, setHidePass] = useState({});

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });
  const localeText = {
    toolbarColumns: t('lbl_Columns'), 
    toolbarDensity: t('lbl_Density'),     
  };
  useEffect(() => {

    var is_location_active = localStorage.getItem('is_active');    
    if(is_location_active === "0")
    {      
      window.location.href = "/in_active";
      return;  
    }
    
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));

    if (permissonData) {
      if (permissonData.access_employees === 0 || permissonData.access_to_online_dashboard === 0) {
        setAccessPage(0);
        return;
      }
    }


    getRoleList();
    getEmployeeList();
  }, []);

  /*
    @function For add employee form submit
  */
  function handleSubmit(event) {
    event.preventDefault();
    const data = {
      id: employeeId,
      name: employeeName,
      email: email,
      password: password,
      pos_passcode: pos_passcode,
      custom_id: custom_id,
      role: selectedRole
    };

    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'create_emp', data, {
      headers: headers
    }).then(response => {
      if (response.data.error) {
        setErrorMsg(response.data.error);
        setError(true);
      } if (response.data.Success) {
        setSuccessMsg(response.data.Success);
        setSuccess(true);
        getRoleList();
        getEmployeeList();
        if (employeeId != 0) { //Check if employee id was not 0
          handleEditEmployee(0, "", "", "", "", false);
        }
        setEmail("");
        setPassword("");
        setPOSPasscode("");
        setEmployeeName("");
        setCustomId("");
        setSelectedRole("");

      }
    }).catch(error => {
      setErrorMsg(t('msg_error_in_api_call'));
      setError(true);
      return error;
    });


  }

  /*
    @function For custom style 
  */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: 0,


    }),
    control: (provided) => ({
      ...provided,
      marginTop: "40px",
      border: 0,
      borderBottom: "1px solid rgb(200,200,200)"

    }),
  }

  /*
    @function For get employee role list 
  */
  const userRoleArr = [];
  const getRoleList = () => {

    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_role_list', data, {
      headers: headers
    }).then(response => {
      const roleName = new Object();
      const empEmails = [];
      //response.data.empData.forEach
      response.data.allUserEmailsData.forEach((eachEmp) => {
        empEmails[eachEmp.email] = eachEmp.name;
      });
      setEmpEmailArr(empEmails);
      response.data.roleData.forEach((eachRole) => {
        roleName[eachRole.id] = eachRole.role_name;
        userRoleArr[eachRole.id] = eachRole.role_name;
      });
      setrole(roleName);
      getEmployeeList();
    }).catch(error => {
      return error;
    });
  }

  /*
    @function For get employees list 
  */
  const getEmployeeList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_emp_list', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.forEach((eachEmp) => {

        let EmployeeListData = {}
        EmployeeListData["Id"] = eachEmp.id;
        EmployeeListData["Name"] = eachEmp.employee_name;
        EmployeeListData["Email"] = eachEmp.employee_email;
        EmployeeListData["Custom_id"] = eachEmp.custom_id;
        EmployeeListData["POS_Passcode"] = eachEmp.pos_passcode;
        EmployeeListData["Role"] = userRoleArr[eachEmp.role_id];
        EmployeeListData["Edit"] = <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditEmployee(eachEmp.id, eachEmp.employee_name, eachEmp.employee_email, eachEmp.custom_id, eachEmp.pos_passcode, eachEmp.role_id)}><EditIcon style={{ height: '20px' }}></EditIcon></button>;
        EmployeeListData["Delete"] = <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setEmployeeId(eachEmp.id); setEmployeeDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>;

        // userArr.push([eachEmp.id, eachEmp.employee_name, eachEmp.employee_email, eachEmp.custom_id, eachEmp.pos_passcode, userRoleArr[eachEmp.role_id], <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditEmployee(eachEmp.id, eachEmp.employee_name, eachEmp.employee_email, eachEmp.custom_id, eachEmp.pos_passcode, eachEmp.role_id)}><EditIcon style={{height:'20px'}}></EditIcon></button>, <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setEmployeeId(eachEmp.id);setEmployeeDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>]);
        userArr.push(EmployeeListData);
      });
      setTableData(userArr);
    }).catch(error => {
      return error;
    });
  }

  const columns = [
    {
      field: 'Id', 
      headerName:  t('lbl_id'), 
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Name',
      headerName: t('lbl_name'),
      flex: 1,
      renderCell: (params) => params.value,

    },
    {
      field: 'Email',
      headerName: t('lbl_email'),
      flex: 1,
      renderCell: (params) => params.value,

    },
    {
      field: 'Custom_id',
      headerName: t('lbl_custom_id'),
      flex: 1,
      renderCell: (params) => params.value,

    },
    {
      field: 'POS_Passcode',
      headerName: t('lbl_pos_passcode'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Role',
      headerName: t('lbl_role'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Edit',
      headerName: t('lbl_edit'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Delete',
      headerName: t('lbl_delete'),
      flex: 1,
      renderCell: (params) => params.value
    },
  ];

  /*
    @function For delete employee 
  */
  const employeeDelete = () => {
    const data = {
      id: employeeId
    };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'delete_emp', data, {
      headers: headers
    }).then(response => {
      setEmployeeId(0);
      setSuccessMsg(t('msg_employee_deleted_successfully'));
      setSuccess(true);
      setEmployeeDeleteDailog(false);
      getRoleList();
      getEmployeeList();
    }).catch(error => {
      return error;
    });
  }

  /*
    @function For edit employee and set employees data
  */
  const handleEditEmployee = (id, employee_name, employee_email, custom_id, pos_passcode, role_id) => {
    setEmployeeId(id);
    setEmployeeName(employee_name);
    setEmail(employee_email);
    setCustomId(custom_id);
    setPOSPasscode(pos_passcode);
    setSelectedRole(role_id);
    setHidePass({ display: "none" });
  }

  /*
    @function For close error popup 
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };
  const handleEmailChange = (e) => {
    if (localStorage.getItem('role') === "admin" && employeeId !== 0) {
      setEmail(e.target.value);
      (empEmailArr[e.target.value]) ? setHidePass({ display: "none" }) : setHidePass({});
    }
    if (employeeId === 0) {
      setEmail(e.target.value);
      (empEmailArr[e.target.value]) ? setHidePass({ display: "none" }) : setHidePass({});
    }


  }
  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_employee_list')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={12}>
                <center>
                  <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                  </h3>
                  
                </center>
                </GridItem>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (tableData) { //Check if table data was come
    var employeeListTable = <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader className="cardheader" color="warning">
          <h4 className={classes.cardTitleWhite}>{t('lbl_employee_list')}</h4>
          <p className={classes.cardCategoryWhite}>

          </p>
        </CardHeader>
        <CardBody>

          {/* 
            * Display employees listing
          */}

          <DataGrid
            style={{ marginTop: "20px" }}
            getRowId={(row) => row.Id}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            slots={{ toolbar: CustomToolbar }}
            rows={tableData}
            columns={columns}            
            disableRowSelectionOnClick
            localeText={localeText}
           
          />

          {/* <Table
            tableHeaderColor="primary"
            tableHead={["ID", "Name", "Email", "Custom id", "POS Passcode", "Role", "Edit", "Delete"]}
            tableData={tableData}
          /> */}
        </CardBody>
      </Card>

    </GridItem>;
  }
  if (role) {
    var empAddForm = <GridItem xs={12} sm={12} md={12}>
      <Card>

        {/* 
          * Add employee form
        */}
        <form onSubmit={handleSubmit}>
          <CardHeader className="cardheader" color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_add_employee')}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText={t('lbl_employee_name')}
                  id="employeename"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={employeeName}
                  onChange={(e) => setEmployeeName(e.target.value.replace(/[^a-z1-9.!@#$%^&**-+, ]/gi, ''))}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText={t('lbl_email_address')}
                  id="emailaddress"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={email}
                  onChange={(e) => { handleEmailChange(e) }}

                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputLabel style={{ marginTop: "30px" }} id="demo-simple-select-label">{t('lbl_select_role')}</InputLabel>
                <Select
                  style={{ width: "100%" }}
                  labelId="Role"
                  id="Role"
                  value={selectedRole}
                  label={t('lbl_role')}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  {
                    Object.entries(role).map(([key, value]) => (
                      <MenuItem key={key} value={key} >
                        {value}
                      </MenuItem>

                    ))
                  }
                </Select>

              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText={t('lbl_pos_passcode')}
                  id="pos_passcode"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{ max: 4, type: 'number' }}
                  value={pos_passcode}
                  onChange={(e) => (e.target.value.length > 4) ? pos_passcode : setPOSPasscode(e.target.value)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText={t('lbl_custom_id')}
                  id="custom_id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={custom_id}
                  onChange={(e) => setCustomId(e.target.value)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={hidePass}>
                <CustomInput
                  labelText={t('lbl_password')}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </GridItem>

            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button id="btnGreenEdit" type="submit" color="warning"> {(employeeId == 0) ? t('lbl_create_employee') : t('lbl_update_employee') }</Button>
          </CardFooter>
        </form>
      </Card>
    </GridItem>;

  }
  return (
    <div>
      {/* 
          Display error message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={Error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* 
          Display success message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
      <GridContainer>

        {/* 
          *Display add employee form
        */}
        {empAddForm}

        {/* 
          * Display employee list table
        */}
        {employeeListTable}

        {/* 
          * Delete category  popup start
        */}
        <Dialog
          open={employeeDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{t('msg_delete_employee')}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setEmployeeId(0); setEmployeeDeleteDailog(false); getRoleList(); getEmployeeList(); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_no')}
            </Button>
            <Button id="btnRedDelete" onClick={employeeDelete} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </div>
  );
}
