/*
  @page Modifier list
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ViewListIcon from '@material-ui/icons/ViewList';
import Helper from "./../../util/Helper";
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
       <Box sx={{ flexGrow: 1 }} />
      {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }}  />  */}
      <GridToolbarColumnsButton slotProps={{          
          button: { variant: 'outlined' },
        }} />
      <GridToolbarDensitySelector slotProps={{          
          button: { variant: 'outlined' },
        }} />
      {/* <GridToolbarFilterButton /> */}

    </GridToolbarContainer>
  );
}

/*
  @const Page styles
*/
const styles = {
  cardModifierWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);

/*
  @function Modifier list page logic
*/
export default function TableList() {

  const {t} = useTranslation();
  const classes = useStyles();
  const [tableOptionData, setModifireOptionData] = useState(false);
  const [tableData, setModifierTableData] = useState(false);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [id, setId] = useState("");
  const [optionId, setOptionId] = useState("");
  const [name, setName] = useState("");
  const [optionName, setOptionName] = useState("");
  const [price, setPrice] = useState("");
  const [minimum_selection, setMinimumSelection] = useState("");
  const [maximum_selection, setMaximumSelection] = useState("");
  const [modiDeleteDailog, SetModiDeleteDailog] = useState(false);
  const [modiOptionDeleteDailog, SetModiOptionDeleteDailog] = useState(false);
  const [accessPage, setAccessPage] = useState(1);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });
  const localeText = {
    toolbarColumns: t('lbl_Columns'), 
    toolbarDensity: t('lbl_Density'),     
  };
  /*
    @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  /*
    @function close modifier option
  */
  const handleCloseOption = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };
  const handleModiOption = (event, id) => {
    setError(false);
    setSuccess(false);
  }


  /*
    @function For get modifier list
  */
  const getModifierList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_modifiers_list', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.forEach((eachModifire) => {

        let itemModifier = {}
        itemModifier["Id"] = eachModifire.id;
        itemModifier["Name"] = eachModifire.name;
        itemModifier["Minimum_Selection"] = eachModifire.minimum_selection;
        itemModifier["Maximum_Selection"] = eachModifire.maximum_selection;
        itemModifier["Option"] = <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => getModifierOptionList(eachModifire.id, eachModifire.name)}><ViewListIcon></ViewListIcon></button>;
        itemModifier["Edit"] = <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditModi(eachModifire.id, eachModifire.name, eachModifire.minimum_selection, eachModifire.maximum_selection)}><EditIcon style={{ height: '20px' }}></EditIcon></button>;
        itemModifier["Delete"] = <button id="reddeleteicon" color="primary" className={classes.button} style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setId(eachModifire.id); SetModiDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>;

        // userArr.push([eachModifire.id, eachModifire.name, eachModifire.minimum_selection, eachModifire.maximum_selection, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => getModifierOptionList(eachModifire.id, eachModifire.name)}><ViewListIcon></ViewListIcon></button>, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditModi(eachModifire.id, eachModifire.name, eachModifire.minimum_selection, eachModifire.maximum_selection)}><EditIcon style={{ height: '20px' }}></EditIcon></button>, <button id="reddeleteicon" color="primary" className={classes.button} style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setId(eachModifire.id); SetModiDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>]);
        userArr.push(itemModifier);
      });
      setModifierTableData(userArr);
      setWait(true);
    }).catch(error => {
      return error;
    });
  }


  const columns = [
    {
      field: 'Id', 
      headerName: t('lbl_id'), 
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Name',
      headerName: t('lbl_name'),
      flex: 1,
      renderCell: (params) => params.value,
  
    },
    {
      field: 'Minimum_Selection',
      headerName: t('lbl_minimum_selection'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Maximum_Selection',
      headerName: t('lbl_maximum_selection'),
      flex: 1,
      renderCell: (params) => params.value,
    },
    {
      field: 'Option',
      headerName: t('lbl_modifiers'),
      flex: 1,
      renderCell: (params) => params.value,
      sortable: false,
      filterable:false
    }
    ,
    {
      field: 'Edit',
      headerName: t('lbl_edit'),
      flex: 1,
      renderCell: (params) => params.value,
      sortable: false,
      filterable:false
    },
    {
      field: 'Delete',
      headerName:  t('lbl_delete'),
      flex: 1,
      renderCell: (params) => params.value,
      sortable: false,
      filterable:false
    },
  ];
  

  /*
    @function For get modifier option list
  */
  const getModifierOptionList = (ModifireId, modifierName) => {
    setModifierTableData(false);
    const data = { modifier_id: ModifireId };
    setId(ModifireId);
    setName(modifierName);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_modifiers_options_list', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.forEach((eachModifire) => {
        var option_price = "";
        option_price = Helper.viewCurrencyFormat(parseFloat(eachModifire.option_price));

        let itemModifierOption = {}
        itemModifierOption["Id"] = eachModifire.id;
        itemModifierOption["Modifier_Name"] = eachModifire.option_name;
        itemModifierOption["Price"] = option_price;
        itemModifierOption["Edit"] = <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditModiOption(eachModifire.id, eachModifire.option_name, option_price)}><EditIcon style={{ height: '20px' }}></EditIcon></button>;
        itemModifierOption["Delete"] = <button id="reddeleteicon" onClick={() => { setOptionId(eachModifire.id); SetModiOptionDeleteDailog(true); }} style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }}><DeleteForeverIcon></DeleteForeverIcon></button>;

        // userArr.push([eachModifire.id, eachModifire.option_name, /*eachModifire.modifier_name,*/ option_price, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditModiOption(eachModifire.id, eachModifire.option_name, option_price)}><EditIcon style={{ height: '20px' }}></EditIcon></button>, <button id="reddeleteicon" onClick={() => { setOptionId(eachModifire.id); SetModiOptionDeleteDailog(true); }} style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }}><DeleteForeverIcon></DeleteForeverIcon></button>]);
        userArr.push(itemModifierOption);
      });
      setModifireOptionData(userArr);

    }).catch(error => {
      return error;
    });
  }

  const optionTableColumn = [
    {
      field: 'Id', 
      headerName: t('lbl_id'), 
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Modifier_Name',
      headerName: t('lbl_modifier_name'),
      flex: 1,
      renderCell: (params) => params.value,
  
    },
    {
      field: 'Price',
      headerName: t('lbl_price'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Edit',
      headerName: t('lbl_edit'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Delete',
      headerName: t('lbl_delete'),
      flex: 1,
      renderCell: (params) => params.value
    },
  ];

  useEffect(() => {
    var is_location_active = localStorage.getItem('is_active');    
    if(is_location_active === "0")
    {      
      window.location.href = "/in_active";
      return;  
    }
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));

    if (permissonData) {
      if (permissonData.access_item_catalog === 0 || permissonData.access_to_online_dashboard === 0) {
        setAccessPage(0);
        return;
      }
    }


    getModifierList();
  }, []);


  /*
    @function handle edit modifier
  */
  const handleEditModi = (id, name, minimum_selection, maximum_selection) => {
    setId(id);
    setName(name);
    setMinimumSelection(minimum_selection);
    setMaximumSelection(maximum_selection);
    setOpenAddModifire(true);
  }

  /*
    @function handle edit modifier option
  */
  const handleEditModiOption = (id, optionname, price) => {
    setOptionId(id);
    setOptionName(optionname);
    setPrice(price);
    setOpenAddOptionModifire(true);
  }

  /*
    @function handle delete modifier
  */
  const handleDeleteModifier = () => {
    setModifierTableData(false);
    SetModiDeleteDailog(false);
    const data = { id: id };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'delete_modifier', data, {
      headers: headers
    }).then(response => {
      getModifierList();
      setId(0);
      if (response.data == 1) {
        setSuccess(true);
        setSuccessMsg(t('msg_modifier_deleted_successfully'));
      } else {
        setError(true);
        setErrorMsg(t('msg_some_error_occur'));
      }
    }).catch(error => {
      getModifierList();
      setId(0);
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });

  }

  // handleModifierNameChange
  const handleModifierNameChange = (event) => {
    const result = event.target.value.replace(/[^a-z1-9 ]/gi, '');
    setName(result)
  }

  const handleModifierOptionNameChange = (event) => {
    const result = event.target.value.replace(/[^a-z1-9 ]/gi, '');
    setOptionName(result)
  }

  /*
    @function handle delete modifier option
  */
  const handleDeleteModifierOption = () => {
    setModifireOptionData(false);
    SetModiOptionDeleteDailog(false);
    const data = { id: optionId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'delete_modifier_option', data, {
      headers: headers
    }).then(response => {
      getModifierOptionList(id, name);
      setOptionId(0);
      if (response.data == 1) {
        setSuccess(true);
        setSuccessMsg(t('msg_modifier_deleted_successfully'));
      } else {
        setError(true);
        setErrorMsg(t('msg_some_error_occur'));
      }
    }).catch(error => {
      getModifierOptionList(id, name);
      setOptionId(0);
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });
  }

  const [openAddModifire, setOpenAddModifire] = React.useState(false);
  const [openAddOptionModifire, setOpenAddOptionModifire] = React.useState(false);


  /*
    @function handle save modifier
  */
  const saveModifire = (id) => {
    setOpenAddModifire(false);
    setModifierTableData(false);
    const data = { id: id, name: name, minimum_selection: minimum_selection, maximum_selection: maximum_selection };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'add_modifier', data, {
      headers: headers
    }).then(response => {
      setId(0);
      setName("");
      setMinimumSelection("");
      setMaximumSelection("");
      getModifierList();
      if (id == 0) { //Check if id was 0
        setSuccess(true);
        setSuccessMsg(t('msg_modifier_group_added_successfully'));
      }
      else {
        setSuccess(true);
        setSuccessMsg(t('msg_modifier_group_updated_successfully'));
      }
    }).catch(error => {
      getModifierList();
      setId(0);
      setName("");
      setMinimumSelection("");
      setMaximumSelection("");
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });
  }

  /*
    @function handle save modifier option
  */
  const saveModifireOption = (optionid) => {
    setOpenAddModifire(false);
    setModifireOptionData(false);
    var data;
    if (price === "" || price === null) //Check price was blank or null
    {
      data = { id: optionid, modifier_id: id, modifier_name: name, option_price: 0.00, option_name: optionName };
    } else {
      data = { id: optionid, modifier_id: id, modifier_name: name, option_price: price, option_name: optionName };
    }
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'add_modifier_option', data, {
      headers: headers
    }).then(response => {
      setOptionId(0);
      setOptionName("");
      setPrice("");
      getModifierOptionList(id, name);
      if (optionid == "" || optionid == null) {  //Check id is not 0    
        setSuccess(true);
        setSuccessMsg(t('msg_modifier_option_added_successfully'));
      }
      else {
        setSuccess(true);
        setSuccessMsg(t('msg_modifier_option_updated_successfully'));
      }
      setOpenAddOptionModifire(false);
    }).catch(error => {
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });

  }
  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_modifier_group')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={12}>
                <center>
                  <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                  </h3>
                  
                </center>
                </GridItem>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!wait) { //Display loading progressbar when data was not come from api
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" >
              <h4 className={classes.cardTitleWhite}>{t('lbl_modifier_group')}</h4>
              <p className={classes.cardModifierWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>{t('lbl_loadind_modifiers')}</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  if (tableOptionData) {
    var optionData = <Card>
      <CardHeader color="warning">
        <h4 className={classes.cardTitleWhite}>{t('lbl_modifier_group')}: {name} </h4>
        <p className={classes.cardModifierWhite}>

        </p>
      </CardHeader>
      <CardBody>
        <Button
          id="btnGreenAdd"
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
          onClick={() => { setOpenAddOptionModifire(true) }}
        >+</Button>
        &nbsp;
        <Button
          id="btnGreenEdit"
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
          onClick={() => { setModifireOptionData(false); getModifierList(); }}
        > {t('lbl_back_to_modifier_groups')}</Button>

        {/* 
              *Display modifier option list
            */}
        <div id="mOptionListTable">


        <DataGrid
            style={{marginTop:"20px"}}
              getRowId={(row) => row.Id}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              slots={{ toolbar: CustomToolbar }}
              rows={tableOptionData}
              columns={optionTableColumn}
              disableRowSelectionOnClick
              localeText={localeText}
            />

          {/* <Table
            tableHeaderColor="primary"
            tableHead={["ID", "Modifier Name","Price", "Edit", "Delete"]}
            tableData={tableOptionData}
          /> */}
        </div>
      </CardBody>
    </Card>;

  } else {

    var optionData = '';

  }


  if (tableData) {

    var mainData = <Card>
      <CardHeader color="warning">
        <h4 className={classes.cardTitleWhite}>{t('lbl_modifier_groups')}</h4>
        <p className={classes.cardModifierWhite}>
        </p>
      </CardHeader>
      <CardBody>
        <Button
          id="btnGreenAdd"
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
          onClick={() => { setOpenAddModifire(true) }}
        >+</Button>

        {/* 
        *Display modifier list 
     */}
        <DataGrid
        style={{marginTop:"20px"}}
         getRowId={(row) => row.Id} 
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          slots={{ toolbar: CustomToolbar }}
          rows={tableData}
          columns={columns}
          localeText={localeText}          
          disableRowSelectionOnClick

        />
        {/* <Table
          tableHeaderColor="primary"
          tableHead={["ID", "Name", "Minimum Selection", "Maximum Selection", "Option", "Edit", "Delete"]}
          tableData={tableData}
        /> */}
      </CardBody>
    </Card>;

  } else {

    var mainData = '';

  }
  return (
    <GridContainer>

      {/* 
          Display error message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* 
          Display success message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      <GridItem xs={12} sm={12} md={12}>
        {mainData}

        {optionData}

        <Dialog open={openAddModifire} onClose={() => { setOpenAddModifire(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{(id == 0) ?  t('lbl_add_new') : t('lbl_update_smallcase')}{" "+t('lbl_modifier_group')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={t('lbl_Modifier_group_name')}
              onChange={handleModifierNameChange}
              value={name}
              fullWidth
            // (e) => setName(e.target.value)
            />
            <TextField
              autoFocus
              margin="dense"
              type="number"
              id="name"
              label={t('lbl_minimum_selection')}
              onChange={(e) => setMinimumSelection(e.target.value)}
              value={minimum_selection}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              type="number"
              label={t('lbl_maximum_selection')}
              onChange={(e) => setMaximumSelection(e.target.value)}
              value={maximum_selection}
              fullWidth
            />

          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }} onClick={() => { setId(0); setName(""); setMinimumSelection(""); setMaximumSelection(""); setOpenAddModifire(false) }} color="primary">
              {t('lbl_cancel_smallcase')}
            </Button>
            <Button id="btnGreenEdit" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }} onClick={() => { saveModifire(id) }} color="primary">
              {(id == 0) ? t('lbl_add_smallcase') : t('lbl_update_smallcase')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add Modifire form end */}


        {/* Add option Modifire form start */}
        <Dialog open={openAddOptionModifire} onClose={() => { setOpenAddOptionModifire(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{(optionId == 0) ? t('lbl_add_new') : t('lbl_update_smallcase')}{" "+t('lbl_modifier')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="optionName"
              label={t('lbl_modifier_name')}
              onChange={handleModifierOptionNameChange}
              value={optionName}
              fullWidth
            // (e) => setOptionName(e.target.value)
            />
            <TextField
              autoFocus
              margin="dense"
              id="price"
              dir="rtl"
              label={t('lbl_price')}
              onChange={(e) => setPrice(Helper.currencyFormatWithDot(e, 'price'))}
              value={price}
              fullWidth
              inputProps={{ min: 0, style: { textAlign: 'right' } }}
            />


          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setOptionId(0); setOptionName(""); setPrice(""); setOpenAddOptionModifire(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_cancel_smallcase')}
            </Button>
            <Button id="btnGreenEdit" onClick={() => { saveModifireOption(optionId) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {(optionId == 0) ? t('lbl_add_smallcase'): t('lbl_update_smallcase')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add option Modifire form end */}

        {/* Delete Modifire  popup start*/}
        <Dialog
          open={modiDeleteDailog}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{t('msg_delete_modifier_group')}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setId(0); SetModiDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_no')}
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteModifier} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_yes')}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete Modifire  popup end*/}

        {/* Delete Modifire option popup start*/}
        <Dialog
          open={modiOptionDeleteDailog}
          keepMounted
          onClose={handleCloseOption}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{t('msg_delete_modifier')}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setOptionId(0); SetModiOptionDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_no')}
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteModifierOption} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_yes')}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete Modifire option popup end*/}
      </GridItem>
    </GridContainer>
  );
}
