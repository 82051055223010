/*
   @page table list page (merchant list)
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import MuiSelect from '@mui/material/Select';
import Select from "react-select";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MUIDataTable from "mui-datatables";
import moment from 'moment';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};


const actions = [
  { label: "pax_batch", value: "pax_batch" },
  { label: "pax_device_manager", value: "pax_device_manager" },
  { label: "pax_discount", value: "pax_discount" },
  { label: "pax_employee", value: "pax_employee" },
  { label: "pax_employee_role", value: "pax_employee_role" },
  { label: "pax_item_category", value: "pax_item_category" },
  { label: "pax_merchant_item", value: "pax_merchant_item" },
  { label: "pax_merchant_modifier", value: "pax_merchant_modifier" },
  { label: "pax_merchant_modifier_options", value: "pax_merchant_modifier_options" },
  { label: "pax_merchant_setting", value: "pax_merchant_setting" },
  { label: "pax_merchant_tip", value: "pax_merchant_tip" },
  { label: "pax_taxes", value: "pax_taxes" },
  { label: "pax_transactions", value: "pax_transactions" },
  { label: "pax_transactions_detail", value: "pax_transactions_detail" },
  { label: "users", value: "users" }   
];

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function merchant list page logic
*/
const useStyles = makeStyles(styles);

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    border: 0,
    background: state.isSelected ? '#77a6ff' : '#FFF',
  }),
  control: (provided) => ({
    ...provided,
    marginTop: "40px",
    border: 0,
    borderBottom: "1px solid rgb(200,200,200)"

  }),

  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar-thumb": {
      background: "#77a6ff"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#4586fd"
    },

  }),


}

const options = {  
};
export default function TableList() {
  const {t} = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [query, setQuery] = useState("");
  const [tableName, setTableName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  
  const [state, setState] = useState({});
 

  const exeQuery = (e) => {
    
    e.preventDefault();
    console.log("q: "+query+" tbl :"+tableName+" sn:"+serialNumber);
    const data = { query:query,table_name:tableName,serial_number:serialNumber};
    console.log(data);
    const headers =
    {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
   
    axios.post(window.apiUrl + 'execute_query_localDatabase', data, {
      headers: headers
    }).then(response => {
      console.log(response);      
      
    }).catch(error => {     
      return error;
    });
    
  }
 
  var permissionData = JSON.parse(localStorage.getItem("admin_permission"));
  if(permissionData.dev_tools == "0")
    {    
          return (
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                  <CardHeader className="cardheader" color="warning">
                    <h4 className={classes.cardTitleWhite}>{t('lbl_developer_tools')}</h4>
                  </CardHeader>
      
                  <CardBody>
                    <GridContainer>
                  <GridItem xs={4} sm={12} md={12}>
                  <center>
                    <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                    </h3>
  
                  </center>
                  <div id="transitInfo"></div>
                  </GridItem>
                    </GridContainer>
                  </CardBody>
      
                </Card>
              </GridItem>
            </GridContainer>);
        
    }

  return (
    <GridContainer>
       
     
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_developer_tools')}</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
          <FormControl fullWidth>
          <GridContainer>

            <GridItem xs={12} sm={12} md={4}>
                <Select
                fullWidth="true"
                label={t('lbl_select_table')}
                variant="outlined"
                      value={actions.filter(function (action) {                        
                        return action.value === tableName;
                      })}
                      onChange={(e) => { setTableName(e.value); }} options={actions}
                      
                    />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <TextField
                fullWidth="true"
                id="outlined-multiline-static"
                label={t('lbl_enter_query')}
                multiline
                rows={2}
                variant="outlined"
                onChange={(e) => {setQuery(e.target.value);} }
                value={query}                
              />
              </GridItem>
      <GridItem xs={12} sm={12} md={4}>
          <TextField         
          variant="outlined"
         fullWidth="true"
         id="outlined-required"
          label={t('lbl_serial_number')}
          value={serialNumber}
          onChange={(e)=>{setSerialNumber(e.target.value)}}
        />
        </GridItem>
        </GridContainer>
          </FormControl>
            <GridContainer>           
              <GridItem xs={12} sm={12} md={3}>
                <center>
                {/* <div style={{ height: "25px",display:"none" }}>&nbsp;</div> */}                 
                    <Button id="commonBlueBtn" className="commonBlueBtn" onClick = {(e)=>{exeQuery(e);}}  variant="contained" color="primary" >
                      {t('lbl_execute_query_on_pos_device')}
                    </Button>
                 
                </center>
              </GridItem>
            </GridContainer>           
            
          </CardBody>
        </Card>

      </GridItem>
    </GridContainer>
  );
}
