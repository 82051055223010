/*
   @page user profile
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import FormControl from "@mui/material/FormControl";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@mui/material/Pagination";
import CustomTable from "components/Table/Table";
import Helper from "../../util/Helper";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from 'react-i18next';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @const Page styles
*/
const styles = {
  grayBg: {
    background: "#8F95A3 !important",
  },
  root: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "#ffa726",
    },
  },
  rowPerPage: {
    minHeight: "40px",
    height: "40px",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#ffa726",
    },
  },
  datepicker: {
    width: "100%",
  },
  partialrefundamnt: {
    marginTop: "0px !important",
  },
  partialrefundpopupbuttonsection: {
    padding: "22px !important",
    paddingBottom: "30px !important",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  datePickerTextBox: {
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    color: "#495057",
    width: "90%",
    height: "45px",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  hiddenContent: { display: "none" },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const {t} = useTranslation();
  const classes = useStyles();
  const [successMsg, setSuccessMsg] = useState("");
  const [resendEmailPopup, setResendEmailPopup] = useState(false);
  const [resendSmsPopup, setResendSmsPopup] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 6)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [tableData, setTableData] = useState([]);
  const [Error, setError] = useState("");
  const [sucess, setSuccess] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");

  const [rowPerPage, setRowPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(10);
  const [currentPages, setCurrentPages] = useState(1);

  const [orderPaymentType, setOrderPaymentType] = useState("");

  const [tabValue, setTabValue] = React.useState(0);

  const addclass = () => {
    document.querySelector(".ps--active-y").classList.remove("newTop");
    document.querySelector(".ps--active-y").classList.add("newTop");
    setTimeout(function () {
      document.querySelector(".ps--active-y").classList.remove("newTop");
    }, 2000);
  };

  useEffect(() => {
    var is_location_active = localStorage.getItem("is_active");
    if (is_location_active === "0") {
      window.location.href = "/in_active";
      return;
    }
    const data = { current_page: currentPages, rowPerPage: rowPerPage };
    getOpenOrderTransaction(data);
  }, []);

  const getOpenOrderTransaction = (body) => {
    setTableData(false);
    const data = body;
    var sDate = new Date(startDate);
    data.startDate = sDate.toISOString().slice(0, 10);
    data.endDate = endDate;

    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");

    console.log("+++++++++++++++++++++++++++++++++++++");
    console.log(data);
    console.log("+++++++++++++++++++++++++++++++++++++");

    axios
      .post(window.apiUrl + "get_open_order", data, {
        headers: headers,
      })
      .then((response) => {
        var userArr = [];

        setTotalPages(response.data.totalData);
        response.data.orderData.forEach((eachOrder) => {
          var tObj = new Date(eachOrder.created_at);
          var statusMsg = "";
          if (eachOrder.is_completed == "1") {
            statusMsg = <div style={{ color: "green" }}>{t('lbl_paid')}</div>;
          } else if (eachOrder.is_completed == "2") {
            statusMsg = <div style={{ color: "red" }}>{t('lbl_voided')}</div>;
          } else {
            statusMsg = <div style={{ color: "red" }}>{t('lbl_not_paid')}</div>;
          }
          userArr.push([
            eachOrder.open_order_id,
            eachOrder.name,
            eachOrder.email ? eachOrder.email : "-",
            <div style={{ textAlign: "right", paddingRight: "40px" }}>
              ${eachOrder.amount}
            </div>,
            statusMsg,
            <span>
              <Button
                variant="contained"
                onClick={() => {
                  setResendEmailPopup(true),
                    setOrderId(eachOrder.open_order_id),
                    setEmail(eachOrder.email),
                    setName(eachOrder.name),
                    setCity(eachOrder.city),
                    setState(eachOrder.state),
                    setZip(eachOrder.zipcode),
                    setPhone(eachOrder.phone),
                    setAmount(eachOrder.amount);
                }}
                style={{
                  marginLeft: "5px",
                  backgroundColor: "#ffa726",
                  color: "#FFFFFF",
                  textTransform: "none",
                  padding: "9px 20px",
                  fontSize: "14px",
                  margin: "10px 0px",
                }}
                color="primary"
                className={classes.button}
              >
                {t('lbl_resend_email')}
              </Button>
            </span>,
            parseInt(tObj.getMonth() + 1) +
              "/" +
              tObj.getDate() +
              "/" +
              tObj.getFullYear(),
          ]);
        });
        setTableData(userArr);
      })
      .catch((error) => {
        setError(true);
        setErrorMsg(t('msg_some_error_occur_to_get_order'));
        return error;
      });
  };

  const handleReload = () => {
    var sDate = new Date(startDate);
    if (endDate === null || endDate === "" || endDate === undefined) {
      setEndDate(new Date());
      var eDate = new Date(new Date());
    } else {
      var eDate = new Date(endDate);
    }
    const data = {
      startDate: sDate.toISOString().slice(0, 10),
      endDate: eDate.toISOString().slice(0, 10),
      current_page: currentPages,
      rowPerPage: rowPerPage,
    };
    getOpenOrderTransaction(data);
  };

  const handleSearch = () => {
    setTableData(false);
    var sDate = new Date(startDate);
    if (endDate === null || endDate === "" || endDate === undefined) {
      setEndDate(new Date());
      var eDate = new Date(new Date());
    } else {
      var eDate = new Date(endDate);
    }
    const data = {
      startDate: sDate.toISOString().slice(0, 10),
      endDate: eDate.toISOString().slice(0, 10),
      current_page: currentPages,
      rowPerPage: rowPerPage,
    };
    getOpenOrderTransaction(data);
  };

  /*
    @function for handle how many record display in single page
  */
  const handelPagination = (e, v, rows) => {
    setTableData(false);
    if (startDate) {
      var sDate = new Date(startDate);
      if (endDate === null || endDate === "" || endDate === undefined) {
        setEndDate(new Date());
        var eDate = new Date(new Date());
      } else {
        var eDate = new Date(endDate);
      }
      const data = {
        startDate: sDate.toISOString().slice(0, 10),
        endDate: eDate.toISOString().slice(0, 10),
        current_page: v,
        rowPerPage: rows,
      };
      getOpenOrderTransaction(data);
    } else {
      const data = { current_page: v, rowPerPage: rows };
      getOpenOrderTransaction(data);
    }
  };

  /*
   * Const custom style
   */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: 0,
    }),
    control: (provided) => ({
      ...provided,
      marginTop: "40px",
      border: 0,
      borderBottom: "1px solid rgb(200,200,200)",
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar-thumb": {
        background: "#80ADFF",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#1769ff",
      },
    }),
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [orderId, setOrderId] = useState("");

  const handlePayment = (PaymentType) => {
    console.log(
      name,
      "-",
      city,
      "-",
      state,
      "-",
      zip,
      "-",
      phone,
      "-",
      email,
      "-",
      amount,
      "-",
      PaymentType,
      "-",
      orderId
    );

    if (email != "") {
      let data = {};
      data.name = name;
      data.city = city;
      data.state = state;
      data.zipcode = zip;
      data.phone = phone;
      data.email = email;
      data.amount = amount;
      data.payment_type = PaymentType;
      data.orderId = orderId;
      data.parent_id = localStorage.getItem("parent_id");
      data.location_id = localStorage.getItem("location_id");

      const headers = {
        Authorization: "Bearer " + localStorage.getItem("merchantToken"),
      };

      axios
        .post(window.apiUrl + "save_open_order", data, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.redirect_link) {
            var w = 800;
            var h = 600;
            var windowPosX = (screen.width - w) / 2;
            var windowPosY =
              (screen.height - h) /
              4; /* for 25%, divide by 4. for 33%, divide by 3, etc. */
            var left = windowPosX + screen.left;
            var top = windowPosY + screen.top;

            window.open(
              response.data.redirect_link,
              "_blank",
              "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
                w +
                ", height=" +
                h +
                ", top=" +
                top +
                ", left=" +
                left
            );

            setSuccess(true);
            setSuccessMsg(t('msg_order_save_successfully'));
          }
          if (response.data.send_email) {
            setSuccess(true);
            setSuccessMsg(t('msg_email_and_sms_sent_successfully'));
            setResendEmailPopup(false);
          }

          setFname("");
          setLname("");
          setName("");
          setCity("");
          setState("");
          setZip("");
          setState("");
          setPhone("");
          setEmail("");
          setAmount("");
          setOrderId("");
          getOpenOrderTransaction();
        })
        .catch((error) => {
          setError(true);
          setErrorMsg(t('msg_some_error_occur'));
          return error;
        });
    } else {
      setError(true);
      setErrorMsg(t('msg_email_not_available'));
      setResendEmailPopup(false);
    }
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Error}
        autoHideDuration={6000}
      >
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={sucess}
        autoHideDuration={6000}
      >
        <Alert severity="success">{successMsg}</Alert>
      </Snackbar>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_virtual_terminal')}</h4>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <span style={{ paddingBottom: "5px" }}>{t('lbl_start_date')}</span>
                    <DatePicker
                      className={classes.datePickerTextBox}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <span style={{ paddingBottom: "5px" }}>{t('lbl_end_date')}</span>
                    <DatePicker
                      className={classes.datePickerTextBox}
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <center>
                    <FormControl
                      sx={{ m: 1, width: "100%", marginTop: "35px" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        id="btnGreenAdd"
                        style={{
                          width: "100%",
                          height: "55px",
                        }}
                        onClick={handleSearch}
                        className={classes.button}
                      >
                        {t('lbl_search')}
                      </Button>
                    </FormControl>
                  </center>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl sx={{ m: 1, width: "100%", marginTop: "35px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnGreenAdd"
                      style={{
                        width: "100%",
                        height: "55px",
                      }}
                      onClick={() => {
                        handleReload();
                      }}
                      className={classes.button}
                    >
                      <RefreshIcon></RefreshIcon>
                    </Button>
                  </FormControl>
                  {/* <Button style={{width:"100%",marginTop:"35px"}} id="commonBlueBtn" onClick={() => { getOpenOrderTransaction() }} variant="contained" color="primary" className={classes.button}>
                    
                  </Button> */}
                </GridItem>
              </GridContainer>

              <div id="virtualTerminalDataTable">
                {tableData ? (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={[
                      t('lbl_order_id'),
                      t('lbl_name'),
                      t('lbl_email'),
                      t('lbl_amount'),
                      t('lbl_status'),
                      t('lbl_action'),
                      t('lbl_created_at'),
                    ]}
                    tableData={tableData}
                  />
                ) : (
                  <center>
                    <CircularProgress id="loginProgress"></CircularProgress>
                  </center>
                )}
              </div>
            </CardBody>
            <div>
              <div style={{ float: "left" }}>
                <FormControl sx={{ m: 1, minWidth: 120, minHeight: 40 }}>
                  {/* Dropdown for select how many transaction list show in single page */}
                  <InputLabel id="demo-simple-select-helper-label">
                    {t('lbl_rows_per_page')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={rowPerPage}
                    label={t('lbl_rows_per_page')}
                    className={classes.rowPerPage}
                    onClick={addclass}
                    onChange={(e) => {
                      setRowPerPage(e.target.value);
                      handelPagination(e, 1, e.target.value);
                    }}
                  >
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  float: "right",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
              >
                <Pagination
                  className={classes.root}
                  classes={{ ul: classes.ul }}
                  style={{ color: "red" }}
                  count={totalPages == 0 ? 1 : totalPages}
                  defaultPage={currentPages}
                  onChange={(e, v) => {
                    handelPagination(e, v, rowPerPage);
                  }}
                  onClick={addclass}
                />
              </div>
            </div>
            <CardFooter></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      {/* resend email Popup start */}
      <Dialog
        open={resendEmailPopup}
        keepMounted
        onClose={() => {}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{t('lbl_confirmation')}</DialogTitle>
        <DialogContent>
          <h5>{t('msg_resend_email')}</h5>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setResendEmailPopup(false);
            }}
            color="primary"
            id="btnGreyCancel"
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button
            onClick={() => {
              handlePayment(1);
            }}
            color="secondery"
            id="btnGreenEdit"
          >
             {t('lbl_send')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* resend email Popup end  */}

      {/* resend sms Popup start */}
      <Dialog
        open={resendSmsPopup}
        keepMounted
        onClose={() => {}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Confirmation</DialogTitle>
        <DialogContent>
          <h5>{t('msg_resend_sms')}</h5>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setResendSmsPopup(false);
            }}
            color="primary"
            id="btnGreyCancel"
          >
            {t('lbl_cancel_smallcase')}
          </Button>
          <Button onClick={() => {}} color="secondery" id="btnGreenEdit">
          {t('lbl_send')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* resend sms Popup end  */}
    </div>
  );
}
