/*
  @page Inactive merchant
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';




/*
  @const Page styles
*/
const styles = {
 
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const useStyles = makeStyles(styles);

/*
  @function employees listing page logic
*/export default function employee() {
const {t} = useTranslation();
  const classes = useStyles(); 
  return (
    <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_access_denied')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={12}>
                <center>
                  <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                  </h3>
                  
                </center>
                </GridItem>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>
  );
}
