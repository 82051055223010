/*
  @page taxes report
*/

//Import require libraries
import React, { useEffect, useState , useRef} from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// import DateTimePicker from '@mui/lab/DateTimePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from 'react-i18next';
/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox: {
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    color: "#495057",
    width: "100%",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  tableheaders: {
    width: "126px",
    backgroundColor: "#E5EFFF",
    height: "40px",
    border: "1.5px solid white",
  },
  oddtrtd: {
    height: "40px",
    backgroundColor: "#f3f4f5",
  },
  eventrtd: {
    height: "40px",
  },
  totaltrtd: {
    height: "40px",
    backgroundColor: "#dee0e1",
  },

  buttonSearch: {
    width: "100%",
    backgroundColor: "#ffa726",
    color: "#FFFFFF",
    padding: "14px 50px",
    "&:hover": {
      backgroundColor: "#ffa726 !important",
      color: "white !important",
    },
  },
  startDateBtn: {
    width: "100% !important",
    paddingLeft: "1%",
    "& div:nth-child(1)": {
      width: "100% !important",
    },
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function taxes report page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const {t} = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange, setDateRange] = React.useState("");
  const [selectEmployee, setEmployee] = React.useState(0);
  const [selectDevice, setDevice] = React.useState(0);
  const [empDropDownVal, setEmpDropDownVal] = useState([]);
  const [devicesDropDownVal, setDevicesDropDownVal] = useState([]);
  const [reportFilter, setReportFilter] = useState({ emp: 0, device: 0 });
  const [reportStartDate, setReportStartDate] = React.useState();
  const [totalApplicableSales, setTotalApplicableSales] = React.useState(0);
  const [totalCollectedAmt, settTotalCollectedAmt] = React.useState(0);
  const [totalReturnAmt, setTotalReturnAmt] = React.useState(0);
  const [totalNetAmt, setTotalNetAmt] = React.useState(0);
  const [csvData, setCsvData] = useState([]);
  const [accessPage, setAccessPage] = useState(1);
  useEffect(() => {
    var is_location_active = localStorage.getItem("is_active");
    if (is_location_active === "0") {
      window.location.href = "/in_active";
      return;
    }
    const permissonData = JSON.parse(localStorage.getItem("permissonData"));

    if (permissonData) {
      if (
        permissonData.access_reports === 0 ||
        permissonData.access_to_online_dashboard === 0
      ) {
        setAccessPage(0);
        return;
      }
    }

    getReportFilterData();
  }, []);

  /*
  @function filtered data page logic
*/
  const getReportFilterData = () => {
    const data = {};
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "get_report_filter_data", data, {
        headers: headers,
      })
      .then((response) => {
        setEmpDropDownVal(response.data.emp);
        var deviceArr = response.data.devices;
        if (response.data.processor == "TSYS TransIT") {
          deviceArr.push({
            is_approve: "1",
            serial_number: t('lbl_virtual_terminal'),
            nick_name: "",
          });
          deviceArr.push({
            is_approve: "1",
            serial_number: t('lbl_payment_link'),
            nick_name: "",
          });
        }
        setDevicesDropDownVal(deviceArr);
        setReportStartDate(response.data.report_start_time.split(":"));
      })
      .catch((error) => {
        return error;
      });
  };


    const csvLink = useRef();
      const handelExport = () => {
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("merchantToken"),
        };
        const data = {
          start_date: moment(startDate).format("YYYY-MM-DD HH:mm:00"),
          end_date: moment(endDate).format("YYYY-MM-DD HH:mm:59"),
          filterData: reportFilter,
        };
        data.parent_id = localStorage.getItem("parent_id");
        data.location_id = localStorage.getItem("location_id");
        axios
          .post(window.apiUrl + "taxes_report", data, {
            headers: headers,
          })
          .then((response) => {
   
            const exportArr = [];
            exportArr.push([t('lbl_taxes_report')]);
            exportArr.push([
              moment(startDate).format("MMM DD,YYYY hh:mm a") +
                " - " +
                moment(endDate).format("MMM DD YYYY hh:mm a"),
            ]);
            // exportArr.push(["Filters:"]);
            exportArr.push([""]);
            exportArr.push([
              t('lbl_tax_rate_or_flat_fee'),
              t('lbl_applicable_sales'),
              t('lbl_amount_collected'),
              t('lbl_amount_refunded'),
              t('lbl_net_amount'),
            ]);
    
            response.data.tableData.map((eachTax, id) => {
              exportArr.push([
                eachTax.name,
                eachTax.applicable_sales,
                eachTax.collected_amt,
                eachTax.return_amt,
                eachTax.net_amt,
              ]);
            });
            exportArr.push([
              "Total",
              response.data.totalApplicableSales,
              response.data.totalCollectedAmt,
              response.data.totalReturnAmt,
              response.data.totalNetAmt,
            ]);
            setCsvData(exportArr);
            csvLink.current.link.click()
          })
          .catch((error) => {
            return error;
          });
      };

  /*
  @function get report data
*/
  const getReportData = (data) => {
    setTableData(false);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "taxes_report", data, {
        headers: headers,
      })
      .then((response) => {
        setTableData(response.data.tableData);
        setTotalApplicableSales(response.data.totalApplicableSales);
        settTotalCollectedAmt(response.data.totalCollectedAmt);
        setTotalReturnAmt(response.data.totalReturnAmt);
        setTotalNetAmt(response.data.totalNetAmt);
      })
      .catch((error) => {
        return error;
      });
  };

  /*
  @Const get employee filtered data
*/
  const employeeChange = (event) => {
    setEmployee(event.target.value);
    let filterData = reportFilter;
    filterData["emp"] = event.target.value;
    setReportFilter(filterData);
  };
  /*
    @Const get device filtered data
  */
  const deviceChange = (event) => {
    setDevice(event.target.value);
    let filterData = reportFilter;
    filterData["device"] = event.target.value;
    setReportFilter(filterData);
  };

  /*
    @function filter using date
  */
  const dateChange = (event) => {
    let selected_date_range = event.target.value;

    switch (selected_date_range) {
      case 1: //Get current day data
        setStartDate(
          new Date().setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        setEndDate(new Date());
        break;
      case 2: //Get yesterday data
        let Yesterday_date = new Date();
        Yesterday_date.setDate(Yesterday_date.getDate() - 1);
        setStartDate(
          Yesterday_date.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        Yesterday_date.setHours(
          23 + parseInt(reportStartDate[0]),
          59 + parseInt(reportStartDate[1]),
          59,
          59
        );
        setEndDate(Yesterday_date);
        break;
      case 3: //Get current week data
        let this_week = new Date();
        this_week.setDate(this_week.getDate() - this_week.getDay());
        setEndDate(new Date());
        setStartDate(
          this_week.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 4: //Get last week data
        let last_week_start_date = new Date();
        let last_week_end_date = new Date();
        last_week_start_date.setDate(
          last_week_start_date.getDate() - last_week_start_date.getDay() - 7
        );
        last_week_start_date.setHours(
          reportStartDate[0],
          reportStartDate[1],
          reportStartDate[2],
          0
        );
        last_week_end_date.setDate(
          last_week_end_date.getDate() - last_week_end_date.getDay() - 1
        );
        last_week_end_date.setHours(
          23 + parseInt(reportStartDate[0]),
          59 + parseInt(reportStartDate[1]),
          59,
          59
        );
        setEndDate(last_week_end_date);
        setStartDate(last_week_start_date);
        break;
      case 5: //Get last 7 days data
        let last_7_day = new Date();
        last_7_day.setDate(last_7_day.getDate() - 6);
        setEndDate(new Date());
        setStartDate(
          last_7_day.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 6: //Get current month data
        let this_month = new Date();
        var firstDay = new Date(
          this_month.getFullYear(),
          this_month.getMonth(),
          1
        );
        var lastDay = new Date(
          this_month.getFullYear(),
          this_month.getMonth() + 1,
          0
        );

        setEndDate(new Date());
        setStartDate(
          new Date(
            this_month.getFullYear(),
            this_month.getMonth(),
            1,
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 7: //Get last month data
        let last_month = new Date();
        setEndDate(
          new Date(
            last_month.getFullYear(),
            last_month.getMonth(),
            0,
            23 + parseInt(reportStartDate[0]),
            59 + parseInt(reportStartDate[1]),
            59,
            59
          )
        );
        setStartDate(
          new Date(
            last_month.getFullYear(),
            last_month.getMonth() - 1,
            1,
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 8: //Get last 30 days data
        let last_30_day = new Date();
        last_30_day.setDate(last_30_day.getDate() - 29);
        setEndDate(new Date());
        setStartDate(
          last_30_day.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 9: //Get last 3 months data
        let last_3_month = new Date();
        last_3_month.setMonth(last_3_month.getMonth() - 3);
        setEndDate(new Date());
        setStartDate(
          last_3_month.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 10: //Get current year data
        let this_year = new Date();
        this_year = new Date(this_year.getFullYear(), 0, 1);
        setStartDate(
          this_year.setHours(
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        setEndDate(new Date());
        break;
      case 11: //Get last year data
        let lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
        setEndDate(
          new Date(
            lastyear.getFullYear(),
            11,
            31,
            23 + parseInt(reportStartDate[0]),
            59 + parseInt(reportStartDate[1]),
            59,
            59
          )
        );
        setStartDate(
          new Date(
            lastyear.getFullYear(),
            0,
            1,
            reportStartDate[0],
            reportStartDate[1],
            reportStartDate[2],
            0
          )
        );
        break;
      case 12:
        setEndDate("");
        setStartDate("");
        break;
      default:
        break;
    }
    setDateRange(event.target.value);
  };

  /*
    @Const search filtered data
  */
  const handleSearch = () => {
    const data = {
      start_date: moment(startDate).format("YYYY-MM-DD HH:mm:00"),
      end_date: moment(endDate).format("YYYY-MM-DD HH:mm:59"),
      filterData: reportFilter,
    };
    getReportData(data);
  };

  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_reporting_taxes')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={12}>
                <center>
                  <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                  </h3>
                  
                </center>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  if (!tableData) {
    //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_reporting_taxes')}</h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <center>
              <h3>{t('lbl_fetching_data')}</h3>
                <CircularProgress id="loginProgress"></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <GridContainer>
                        {csvData ? (
                          <CSVLink
                            data={csvData}
                            ref={csvLink}
                            target="_blank"
                            filename={"export-taxes-report.csv"}
                          ></CSVLink>
                        ) : (
                          ""
                        )} 
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_reporting_taxes')}</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          {/*
           * Filter options
           */}
          <CardBody>
            <GridContainer>
              <GridItem
                id="gidaterange"
                xs={12}
                sm={12}
                md={4}
                style={{ marginTop: "16px" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                  {t('lbl_date_range')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={dateRange}
                    label={t('lbl_date_range')}
                    onChange={dateChange}
                  >
                    <MenuItem value="">
                    <em>{t('lbl_none')}</em>
                    </MenuItem>
                    <MenuItem value={1}>{t('lbl_today')}</MenuItem>
                    <MenuItem value={2}>{t('lbl_yesterday')}</MenuItem>
                    <MenuItem value={3}>{t('lbl_this_week')}</MenuItem>
                    <MenuItem value={4}>{t('lbl_last_week')}</MenuItem>
                    <MenuItem value={5}>{t('lbl_last_7_days')}</MenuItem>
                    <MenuItem value={6}>{t('lbl_this_month')}</MenuItem>
                    <MenuItem value={7}>{t('lbl_last_month')}</MenuItem>
                    <MenuItem value={8}>{t('lbl_last_30_days')}</MenuItem>
                    <MenuItem value={9}>{t('lbl_last_3_months')}</MenuItem>
                    <MenuItem value={10}>{t('lbl_this_year')}</MenuItem>
                    <MenuItem value={11}>{t('lbl_last_year')}</MenuItem>
                    <MenuItem value={12}>{t('lbl_custom_date_range')}</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem
                id="giemployee"
                xs={12}
                sm={12}
                md={4}
                style={{ marginTop: "16px" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                  {t('lbl_employees')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectEmployee}
                    label={t('lbl_employees')}
                    onChange={employeeChange}
                  >
                    <MenuItem value={0}>All Employees</MenuItem>
                    {empDropDownVal.map((eachEmp, id) => {
                      return (
                        <MenuItem value={eachEmp.user_id}>
                          {eachEmp.employee_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem
                id="gidevice"
                xs={12}
                sm={12}
                md={4}
                style={{ marginTop: "16px" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                  {t('lbl_source')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="chart"
                    value={selectDevice}
                    label={t('lbl_source')}
                    onChange={deviceChange}
                  >
                    <MenuItem value={0}>{t('lbl_all_sources')}</MenuItem>
                    {devicesDropDownVal.map((eachDevice, id) => {
                      var val =
                      eachDevice.serial_number +
                      "(" +
                      eachDevice.nick_name +
                      ")";
                      var deviceDropDownMenu = eachDevice.serial_number;
                      if(eachDevice.serial_number == "Virtual Terminal" || eachDevice.serial_number == "Terminal virtual")
                      {
                         val = t('lbl_virtual_terminal');
                         deviceDropDownMenu = val;
                      }
                      if(eachDevice.serial_number == "Payment Link" ||  eachDevice.serial_number == "Enlace de pago")
                      {
                        val = t('lbl_payment_link');
                        deviceDropDownMenu = val;
                      }
                      return (
                        <MenuItem value={deviceDropDownMenu}>
                          {val}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={4}
                id="startdateandtime"
                style={{ marginTop: "20px" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label={t('lbl_start_date')}
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={4}
                id="startdateandtime"
                style={{ marginTop: "20px" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label={t('lbl_end_date')}
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={2} style={{ marginTop: "20px" }}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <Button
                    id="btnsearch"
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    className={`${classes.button} ${classes.buttonSearch}`}
                  >
                     {t('lbl_search')}
                  </Button>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={2} style={{ marginTop: "20px" }}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  {/* <CSVLink data={csvData} color="primary"> */}
                    <Button
                      id="btnGreenAdd"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handelExport();
                      }}
                      className={`${classes.button} ${classes.buttonSearch}`}
                    >
                      {t('lbl_export')}
                    </Button>
                  {/* </CSVLink> */}
                </FormControl>
              </GridItem>
            </GridContainer>
            <br></br>
            <br></br>

            <GridContainer>
              {/*
               * Display taxes report
               */}
              <div style={{ width: "100%" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{ textAlign: "left", paddingLeft: "10px" }}
                        className={classes.tableheaders}
                      >
                       {t('lbl_tax_rate_or_flat_fee')}
                      </th>
                      <th className={classes.tableheaders}>{t('lbl_applicable_sales')}</th>
                      <th className={classes.tableheaders}>{t('lbl_amount_collected')}</th>
                      <th className={classes.tableheaders}>{t('lbl_amount_refunded')}</th>
                      <th className={classes.tableheaders}>{t('lbl_net_amount')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((eachTax, id) => {
                      return (
                        <tr>
                          <td
                            style={{ paddingLeft: "10px" }}
                            className={classes.oddtrtd}
                          >
                            <b>{eachTax.name}</b>
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "20px" }}
                            className={classes.oddtrtd}
                          >
                            ${eachTax.applicable_sales}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "20px" }}
                            className={classes.oddtrtd}
                          >
                            ${eachTax.collected_amt}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "20px" }}
                            className={classes.oddtrtd}
                          >
                            ${eachTax.return_amt}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "20px" }}
                            className={classes.oddtrtd}
                          >
                            ${eachTax.net_amt}
                          </td>
                        </tr>
                      );
                    })}
                    <tr style={{ borderTop: "1px solid darkgray" }}>
                      <td
                        style={{ paddingLeft: "10px" }}
                        className={classes.totaltrtd}
                      >
                        <b>{t('lbl_total')}</b>
                      </td>
                      <td
                        style={{ textAlign: "right", paddingRight: "20px" }}
                        className={classes.totaltrtd}
                      >
                        <b>${totalApplicableSales}</b>
                      </td>
                      <td
                        style={{ textAlign: "right", paddingRight: "20px" }}
                        className={classes.totaltrtd}
                      >
                        <b>${totalCollectedAmt}</b>
                      </td>
                      <td
                        style={{ textAlign: "right", paddingRight: "20px" }}
                        className={classes.totaltrtd}
                      >
                        <b>${totalReturnAmt}</b>
                      </td>
                      <td
                        style={{ textAlign: "right", paddingRight: "20px" }}
                        className={classes.totaltrtd}
                      >
                        <b>${totalNetAmt}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
