/*
  @page Forgot password
*/

//Import require libraries
import React,{useState } from "react";
import axios from 'axios';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LogoPath from "assets/img/Logo.png";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { t } from "i18next";

/*
  @function for magicpay main logo
*/
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t('lbl_copyright')} © {" "}
      <Link color="inherit" href="https://magicpay.net/">
       MagicPay Merchant Services LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @const Page styles
*/
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/*
  @function Forgot password page logic
*/
export default function LoginPage() {
 const {t,i18n} = useTranslation();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [loginError, setLoginError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [sucess, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  
  
/*
  @function email address validation
*/
  function validateForm() {
    return email.length > 0 ;
  }
  
  /*
    @function For close error popup 
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setLoginError(false);
    setSuccess(false);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);  
    const currentPage = location.pathname;      
    const roloadPagesArr = ['/admin/table', '/admin/device_manager','/admin/incorrect_billing_amount_Kushki','/admin/admin_employees'];
    if( roloadPagesArr.includes(currentPage)){    
      window.location.reload();
    }
  };

  /*
    @function For forgot password form submit 
  */
  function handleSubmit(event) {
    event.preventDefault();
    document.getElementById("loginProgress").style.display = "block";
    const data = { email:email};
    data.parent_id = localStorage.getItem('parent_id');
    axios.post(window.apiUrl+'forgot_password', data)
        .then(response => {
          document.getElementById("loginProgress").style.display = "none";
          setSuccess(true);
          setSuccessMsg(t('msg_email_has_been_sent_to_your_email_address'));         
          
        }) .catch(error => {
          document.getElementById("loginProgress").style.display = "none";
          if(error.response.status === 401)
          {
            if(error.response.data.activateError)
            {
              setErrorMsg(error.response.data.activateError);
            }else
            {
              setErrorMsg(t('msg_Invalid_eMail_or_Password'));
            }
            setLoginError(true);
          }  
          if(error.response.status === 500)
          {
            setErrorMsg(t('msg_Email_address_not_found'));
            setLoginError(true);
          }        
          return error;
        });
                
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={loginError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
      <div className={classes.paper}>
         <img src={LogoPath}></img>
         <Typography component="h1" variant="h5">
          {t('txt_forgot_password')}
        </Typography>
        <CircularProgress id="loginProgress" style={{display:"none"}}></CircularProgress>
        
        {/* 
          * Display forgot password form
        */}
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('lbl_email_address')}
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />          
          <div id="divLoginBtn">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="warning"
              className={classes.submit}
              disabled={!validateForm()}
            >
              {t('txt_forgot_password')}
            </Button>
          </div>
        </form>
      </div>

      {/* Display copyright */}
      <div id="copyRightDiv">
      <Box mt={8}>
        <Copyright />
      </Box>
      </div>
      <Grid container>
            <Grid item xs>
             <div style={{marginTop:"20px"}}>
                <div style={{display:"flex"}}>
                  {/* <i class="fa fa-language" aria-hidden="true" style={{fontSize:"20px"}}></i> */}
                  <span style={{ cursor: "pointer",display:"flex",fontWeight:"400" }} onClick={() => changeLanguage('en')}>EN <div class="languageFlag loginLanguageChangeSec" id="en"></div></span> &nbsp;| &nbsp; <span style={{ cursor: "pointer",display:"flex",fontWeight:"400" }} onClick={() => changeLanguage('es')}>ES <div class="languageFlag loginLanguageChangeSec" id="mx"></div></span>
                </div>       
             </div>
            </Grid>
          </Grid>
    </Container>
  );
}