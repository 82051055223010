/*
  @page employee report
*/

//Import require libraries
import React,{ useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DateTimePicker from '@mui/lab/DateTimePicker';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {CSVLink, CSVDownload} from 'react-csv';
import { useTranslation } from 'react-i18next';

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox:{
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#495057',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  tabletrth:{
    borderRight:"1.5px solid white",
    borderLeft:"1.5px solid white"
  },
  tabletrthtd:{
    textAlign:"right",
    paddingRight:"10px"
  },
  tablebodytr:{
    height:"50px",
    // borderBottom:".5px solid gray"
  },
  buttonSearch:{
    width: "100%",
    marginLeft: "8px !important",
    backgroundColor: '#ffa726', 
    color: '#FFFFFF', 
    padding: "14px 50px",
    "&:hover": {
      backgroundColor: "#ffa726 !important",
      color:"white !important"
    }
  },  
  startDateBtn :{   
      width: "100% !important",
      paddingLeft:"1%",
      '& div:nth-child(1)':{
        width: "100% !important",
      }     
  }
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function employee report page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const {t} = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = React.useState('');
  const [reportStartDate, setReportStartDate] = React.useState();
  const [empDropDownVal, setEmpDropDownVal] = useState([]);
  const [devicesDropDownVal, setDevicesDropDownVal] = useState([]);
  const [accessPage, setAccessPage] = useState(1);
  useEffect(() => {
      
    var is_location_active = localStorage.getItem('is_active');    
    if(is_location_active === "0")
    {      
      window.location.href = "/in_active";
      return;  
    }
 const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_reports === 0 || permissonData.access_to_online_dashboard === 0)
      {
        setAccessPage(0);
        return;
      }
    }
    getReportFilterData();
    getReportData({});
  }, []);

  /*
    @Const search filtered data
  */
  const handleSearch = () =>{
    const data = { 'start_date': moment(startDate).format('YYYY-MM-DD HH:mm:00'), 'end_date': moment(endDate).format('YYYY-MM-DD HH:mm:59')};    
    getReportData(data);
  }

  /*
    @function filtered data page logic
  */
  const getReportFilterData = () =>
  {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }    
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'get_report_filter_data', data, {
      headers: headers
    }).then(response => {      
        setEmpDropDownVal(response.data.emp);
        setDevicesDropDownVal(response.data.devices);
        setReportStartDate(response.data.report_start_time.split(":"));
    }).catch(error => {     
      return error;
    });
  
  }


  const csvLink = useRef();
    const handelExport = () => {
      const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
      }  
      const data = { 'start_date': moment(startDate).format('YYYY-MM-DD HH:mm:00'), 'end_date': moment(endDate).format('YYYY-MM-DD HH:mm:59')};    
      data.parent_id = localStorage.getItem('parent_id');  
      data.location_id = localStorage.getItem('location_id');
      axios.post(window.apiUrl +'emp_sales_report', data, {
        headers: headers
      }).then(response => {        
  
        const exportArr = [];
        exportArr.push([t('lbl_employee_sales_report')]);
        exportArr.push([moment(startDate).format('MMM DD,YYYY hh:mm a')+" - "+moment(endDate).format('MMM DD YYYY hh:mm a')]);
        exportArr.push([""]); 
        exportArr.push([" ",t('lbl_payments'),t('lbl_payments'),t('lbl_refund_btn'),t('lbl_refund_btn')," "]);
        exportArr.push([t('lbl_employee_name'),t('lbl_count'),t('lbl_amount'),t('lbl_count'),t('lbl_amount'),t('Tips')]);        
  
        response.data.tableData.map((eachRow, id) => {
          exportArr.push([eachRow.name,eachRow.sale_cnt,eachRow.sale_amount,eachRow.retrun_cnt,eachRow.return_amount,eachRow.tip_amount]); 
        })
        setCsvData(exportArr);
        csvLink.current.link.click()
        
      }).catch(error => {      
        return error;
      });
    };

  /*
    @function report data
  */
  const getReportData = (data) => {   
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }  
    data.parent_id = localStorage.getItem('parent_id');  
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl +'emp_sales_report', data, {
      headers: headers
    }).then(response => {
      setTableData(response.data.tableData); 
      

      // const exportArr = [];
      // exportArr.push([t('lbl_employee_sales_report')]);
      // exportArr.push([moment(startDate).format('MMM DD,YYYY hh:mm a')+" - "+moment(endDate).format('MMM DD YYYY hh:mm a')]);
      // exportArr.push([""]); 
      // exportArr.push([" ","payment","payment","refund","refund"," "]);
      // exportArr.push(["employee name","count","amount","count","amount","tips"]);
      

      // response.data.tableData.map((eachRow, id) => {
      //   exportArr.push([eachRow.name,eachRow.sale_cnt,eachRow.sale_amount,eachRow.retrun_cnt,eachRow.return_amount,eachRow.tip_amount]); 
      // })
      // setCsvData(exportArr);

      
    }).catch(error => {      
      return error;
    });
  }

  /*
    @function filter using date
  */
  const dateChange = (event) => {
    let selected_date_range = event.target.value;

    switch (selected_date_range) {
      case 1: //Get current day data         
        setStartDate(new Date().setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        setEndDate(new Date());
        break;
      case 2: //Get yesterday data
        let Yesterday_date = new Date();
        Yesterday_date.setDate(Yesterday_date.getDate() - 1);
        setStartDate(Yesterday_date.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));        
        Yesterday_date.setHours(23+ parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59);
        setEndDate(Yesterday_date);
        break;
      case 3: //Get current week data
        let this_week = new Date();
        this_week.setDate(this_week.getDate() - this_week.getDay());
        setEndDate(new Date());
        setStartDate(this_week.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 4: //Get last week data
        let last_week_start_date = new Date();
        let last_week_end_date = new Date();
        last_week_start_date.setDate(last_week_start_date.getDate() - last_week_start_date.getDay() - 7);
        last_week_start_date.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0);
        last_week_end_date.setDate(last_week_end_date.getDate() - last_week_end_date.getDay() - 1);
        last_week_end_date.setHours(23 +  parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59)
        setEndDate(last_week_end_date);
        setStartDate(last_week_start_date);
        break;
      case 5: //Get last 7 days data
        let last_7_day = new Date();
        last_7_day.setDate(last_7_day.getDate() - 6);
        setEndDate(new Date());
        setStartDate(last_7_day.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 6: //Get current month data
        let this_month = new Date();
        var firstDay = new Date(this_month.getFullYear(), this_month.getMonth(), 1);
        var lastDay = new Date(this_month.getFullYear(), this_month.getMonth() + 1, 0);

        setEndDate(new Date());
        setStartDate(new Date(this_month.getFullYear(), this_month.getMonth(), 1,reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 7: //Get last month data
        let last_month = new Date();
        setEndDate(new Date(last_month.getFullYear(), last_month.getMonth(), 0,23+ parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59));
        setStartDate(new Date(last_month.getFullYear(), last_month.getMonth() - 1, 1,reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 8: //Get last 30 days data
        let last_30_day = new Date();
        last_30_day.setDate(last_30_day.getDate() - 29)
        setEndDate(new Date());
        setStartDate(last_30_day.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 9: //Get last 3 months data
        let last_3_month = new Date();
        last_3_month.setMonth(last_3_month.getMonth() - 3)
        setEndDate(new Date());
        setStartDate(last_3_month.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 10: //Get current year data
          let this_year = new Date();                              
          this_year = new Date(this_year.getFullYear(), 0, 1);
          setStartDate(this_year.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));  
          setEndDate(new Date());
      break;
      case 11: //Get last year data
          let lastyear = new Date(new Date().getFullYear() - 1, 0, 1);          
          setEndDate(new Date(lastyear.getFullYear(), 11, 31,23+ parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59));
          setStartDate(new Date(lastyear.getFullYear(), 0, 1,reportStartDate[0],reportStartDate[1],reportStartDate[2],0));  
      break;
      case 12:          
          setEndDate("");
          setStartDate("");  
      break;   
      default:
        break;
    }    
    setDateRange(event.target.value);
  };

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_reporting_employee_sales')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={12}>
                <center>
                  <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                  </h3>
                  
                </center>
                </GridItem>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  return (
    <GridContainer>
                  {csvData ? (
                    <CSVLink
                      data={csvData}
                      ref={csvLink}
                      target="_blank"
                      filename={"export-employee-report.csv"}
                    ></CSVLink>
                  ) : (
                    ""
                  )} 
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_reporting_employee_sales')}</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          
          {/* 
            * Filter options
          */}
          <CardBody>
          <GridContainer>
          <GridItem id="gidaterange" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>              
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">{t('lbl_date_range')}</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={dateRange}
                  label={t('lbl_date_range')}
                  onChange={dateChange}
                >
                  <MenuItem value="">
                  <em>{t('lbl_none')}</em>
                    </MenuItem>
                    <MenuItem value={1}>{t('lbl_today')}</MenuItem>
                    <MenuItem value={2}>{t('lbl_yesterday')}</MenuItem>
                    <MenuItem value={3}>{t('lbl_this_week')}</MenuItem>
                    <MenuItem value={4}>{t('lbl_last_week')}</MenuItem>
                    <MenuItem value={5}>{t('lbl_last_7_days')}</MenuItem>
                    <MenuItem value={6}>{t('lbl_this_month')}</MenuItem>
                    <MenuItem value={7}>{t('lbl_last_month')}</MenuItem>
                    <MenuItem value={8}>{t('lbl_last_30_days')}</MenuItem>
                    <MenuItem value={9}>{t('lbl_last_3_months')}</MenuItem>
                    <MenuItem value={10}>{t('lbl_this_year')}</MenuItem>
                    <MenuItem value={11}>{t('lbl_last_year')}</MenuItem>
                    <MenuItem value={12}>{t('lbl_custom_date_range')}</MenuItem>
                </Select>
              </FormControl>
              
            </GridItem>
            <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}} >
            <FormControl sx={{ m: 1, width: "100%" }}>
                  
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker   
                               
                  label={t('lbl_start_date')}
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                    
                  />
                  </LocalizationProvider>
                  </FormControl>
                </GridItem>                
                <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}}>
                <FormControl sx={{ m: 1, width: "100%" }}>             
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker        
                  label={t('lbl_end_date')}        
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  </LocalizationProvider>
                  </FormControl>
                </GridItem>
            </GridContainer>
            <GridContainer>
            <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}}></GridItem>
            <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}}></GridItem>

                <GridItem xs={12} sm={12} md={2}  style={{marginTop:"20px"}}>
                  <Button id="btnsearch" variant="contained" color="primary" onClick={handleSearch} className={`${classes.button} ${classes.buttonSearch}`}>
                  {t('lbl_search')}
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}  style={{marginTop:"20px"}}>
                
                  {/* <CSVLink data={csvData} color="primary" > */}
                  <Button 
                  id="btnGreenAdd" 
                  variant="contained" 
                  color="primary"  
                  onClick={() => {
                    handelExport();
                  }}
                  className={`${classes.button} ${classes.buttonSearch}`
                  }>
                  {t('lbl_export')}
                    </Button>
                {/* </CSVLink> */}
                  
                  </GridItem>
          </GridContainer><br></br><br></br>
            <GridContainer>
                
                {/* 
                  * Display employee report
                */}
                <div style={{width:"100%",marginTop:"50px",overflowX:"auto"}}>
                    <table style={{width:"100%",borderCollapse:"collapse",border:"1px solid white"}}>
                      <thead>
                        <tr style={{backgroundColor:"#B3CEFF", color:"black",height:"40px"}}>
                          <th style={{borderBottom:"1.5px solid white"}} className={classes.tabletrth} colSpan={2}>{t('lbl_name')}</th>
                          <th style={{borderBottom:"1.5px solid white"}} className={classes.tabletrth} colSpan={2}>{t('lbl_payments')}</th>
                          <th style={{borderBottom:"1.5px solid white"}} className={classes.tabletrth} colSpan={2}>{t('lbl_refunds')}</th>
                          {/* <th style={{borderBottom:"1px solid gray"}} className={classes.tabletrth} colSpan={2}>Manual Refunds</th> */}
                          {/* <th style={{borderBottom:"1px solid gray"}} className={classes.tabletrth} colSpan={2}>Service Charge</th> */}
                          <th style={{borderBottom:"1.5px solid white"}} className={classes.tabletrth} colSpan={2}>{t('lbl_tips')}</th>
                        </tr>
                        <tr style={{backgroundColor:"#E5EFFF"}}>  
                          <th style={{borderLeft:"1.5px solid white"}}></th>
                          <th></th>                      
                          <th className={classes.tabletrth}>#</th>
                          <th className={classes.tabletrth}>{t('lbl_amount')}</th>
                          <th className={classes.tabletrth}>#</th>
                          <th className={classes.tabletrth}>{t('lbl_amount')}</th>                                                    
                          {/* <th style={{borderRight:"1px solid gray"}}></th> */}
                          <th></th>
                          {/* <th></th> */}
                        </tr>
                      </thead>
                      <tbody>
                      {tableData.map((eachRow, id) => {
                          //  setGrossTotal(grossTotal+eachRow.gross_sale);
                          return <tr id="trEmpReportTbTr" className={classes.tablebodytr}>
                          <td style={{textAlign:"left",paddingLeft:"10px"}} className={classes.tabletrthtd}>{eachRow.name}</td>
                          <td className={classes.tabletrthtd}></td>
                          <td className={classes.tabletrthtd}>{eachRow.sale_cnt}</td>
                          <td className={classes.tabletrthtd}>${eachRow.sale_amount}</td>
                          <td className={classes.tabletrthtd}>{eachRow.retrun_cnt}</td>
                          <td className={classes.tabletrthtd}>${eachRow.return_amount}</td>
                          
                          <td className={classes.tabletrthtd}>${eachRow.tip_amount}</td>
                          {/* <td className={classes.tabletrthtd}>Details</td>                         */}
                        </tr>

                        })}
                     
                      </tbody>
                    </table>
                </div>
            </GridContainer>
              
          </CardBody>
        </Card>
        
      </GridItem>
    </GridContainer>
  );
}
